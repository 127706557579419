<template>
  <BlockStack :gap="400">
    <NotificationMessage :scope="$options.name"></NotificationMessage>
    <BlockStack :gap="600" class="tw-w-full">
      <div class="tw-flex gap-2 tw-w-full">
        <!-- city -->
        <BlockStack :gap="200" class="tw-w-1/2">
          <label
            for="city"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
            >City<span
              v-if="!address.city"
              class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
              >required</span
            >
          </label>
          <input
            type="text"
            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
            :class="{
              'tw-ring-red-300': !address.city,
              'tw-ring-green-500': address.city,
            }"
            name="city"
            id="city"
            required
            placeholder="St. Louis"
            aria-label="St. Louis"
            v-model="address.city"
          />
        </BlockStack>
        <!-- state -->
        <BlockStack :gap="200" class="tw-w-1/2">
          <label
            for="state"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
            >State<span
              v-if="!getState"
              class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
              >required</span
            >
          </label>
          <select
            class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg"
            :class="{
              'tw-ring-red-300': !getState,
              'tw-ring-green-500': getState,
            }"
            required
            v-model="address.state"
            name="stateSelect"
            id="stateSelect"
          >
            <option value="" class="" disabled>Select state</option>
            <option
              v-for="option in stateOptions"
              :value="option.value"
              :key="option.index"
            >
              {{ option.text }} ({{ option.value }})
            </option>
          </select>
        </BlockStack>
      </div>
      <BlockStack :gap="200" class="tw-bg-gray-100 tw-p-2">
        <p class="tw-text-gray-900 tw-m-0">
          Make this region public? Making a region public means that all users
          (customers and admin) will see this region as options when exploring
          the gift cards catalog.
        </p>
        <BlockStack :gap="100">
          <div class="tw-flex tw-items-center tw-space-x-4">
            <label class="tw-flex tw-items-center tw-space-x-2">
              <input
                type="radio"
                v-model="address.metadata.isOnline"
                :value="true"
                class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
              />
              <span class="tw-text-gray-700">Yes</span>
            </label>
            <label class="tw-flex tw-items-center tw-space-x-2">
              <input
                type="radio"
                v-model="address.metadata.isOnline"
                :value="false"
                class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
              />
              <span class="tw-text-gray-700">No (default)</span>
            </label>
          </div>
        </BlockStack>
      </BlockStack>
      <!-- <div class="tw-flex gap-2 tw-w-full">
        <BlockStack :gap="200" class="tw-w-1/2">
          <label
            for="postalCode"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
            >Postal Code</label
          >
          <input
            type="text"
            name="postalCode"
            id="postalCode"
            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
            placeholder="11111"
            aria-label="11111"
            v-model="address.postalCode"
          />
        </BlockStack>
        <BlockStack :gap="200" class="tw-w-1/2">
          <label
            for="country"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
            >Country</label
          >
          <input
            :readonly="true"
            type="url"
            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6"
            name="country"
            id="country"
            required
            placeholder="country"
            v-model="address.country"
          />
        </BlockStack>
      </div> -->
    </BlockStack>
  </BlockStack>
  <ModalFooter>
    <div class="tw-flex tw-gap-4 tw-items-center tw-mt-6">
      <TheButton
        type="button"
        variant="primary"
        size="large"
        :disabled="!this.regionFormIsComplete"
        @click="saveNewRegion()"
      >
        <template #text>Save region</template>
      </TheButton>
      <TheButton
        type="button"
        variant="tertiary"
        size="large"
        @click="closeModal()"
      >
        <template #text>Cancel</template>
      </TheButton>
    </div>
  </ModalFooter>
</template>

<script>
import BlockStack from "../../layout/BlockStack.vue";
import ModalFooter from "../../layout/ModalFooter.vue";
import TheButton from "../../actions/TheButton.vue";
import NotificationMessage from "../../feedback/NotificationMessage.vue";
import MultiSelectAccordion from "../../elements/accordion/MultiSelectAccordion.vue";

export default {
  name: "GroupForm",
  emits: ["closeModal", "reloadVendors"],
  components: {
    MultiSelectAccordion,
    BlockStack,
    ModalFooter,
    TheButton,
    NotificationMessage,
  },
  props: {
    members: {
      type: Array,
      default: null,
    },
  },
  created() {
    // this.getGroupTags();
  },
  data() {
    return {
      address: {
        city: "",
        state: "",
        country: "US",
        metadata: {
          isRegionAddress: true,
          isOnline: false,
        },
      },
      stateOptions: [
        { text: "Alabama", value: "AL" },
        { text: "Alaska", value: "AK" },
        { text: "Arizona", value: "AZ" },
        { text: "Arkansas", value: "AR" },
        { text: "California", value: "CA" },
        { text: "Colorado", value: "CO" },
        { text: "Connecticut", value: "CT" },
        { text: "Delaware", value: "DE" },
        { text: "Florida", value: "FL" },
        { text: "Georgia", value: "GA" },
        { text: "Hawaii", value: "HI" },
        { text: "Idaho", value: "ID" },
        { text: "Illinois", value: "IL" },
        { text: "Indiana", value: "IN" },
        { text: "Iowa", value: "IA" },
        { text: "Kansas", value: "KS" },
        { text: "Kentucky", value: "KY" },
        { text: "Louisiana", value: "LA" },
        { text: "Maine", value: "ME" },
        { text: "Maryland", value: "MD" },
        { text: "Massachusetts", value: "MA" },
        { text: "Michigan", value: "MI" },
        { text: "Minnesota", value: "MN" },
        { text: "Mississippi", value: "MS" },
        { text: "Missouri", value: "MO" },
        { text: "Montana", value: "MT" },
        { text: "Nebraska", value: "NE" },
        { text: "Nevada", value: "NV" },
        { text: "New Hampshire", value: "NH" },
        { text: "New Jersey", value: "NJ" },
        { text: "New Mexico", value: "NM" },
        { text: "New York", value: "NY" },
        { text: "North Carolina", value: "NC" },
        { text: "North Dakota", value: "ND" },
        { text: "Ohio", value: "OH" },
        { text: "Oklahoma", value: "OK" },
        { text: "Oregon", value: "OR" },
        { text: "Pennsylvania", value: "PA" },
        { text: "Rhode Island", value: "RI" },
        { text: "South Carolina", value: "SC" },
        { text: "South Dakota", value: "SD" },
        { text: "Tennessee", value: "TN" },
        { text: "Texas", value: "TX" },
        { text: "Utah", value: "UT" },
        { text: "Vermont", value: "VT" },
        { text: "Virginia", value: "VA" },
        { text: "Washington", value: "WA" },
        { text: "West Virginia", value: "WV" },
        { text: "Wisconsin", value: "WI" },
        { text: "Wyoming", value: "WY" },
      ],
      selectedRegion: null,
    };
  },
  computed: {
    regionFormIsComplete() {
      if (!this.getCity || !this.getState) {
        return false;
      }
      return true;
    },
    getCity() {
      return this?.address?.city;
    },
    getState() {
      return this?.address?.state;
    },
    getPostalCode() {
      return this?.address?.postalCode;
    },
    getCountry() {
      return this?.address?.country;
    },
  },
  methods: {
    async saveNewRegion() {
      try {
        // Get the Bold client
        const api = await this.getBoldClient();

        // Create an address object
        let newAddress = this.address;
        console.log("newAddress", newAddress);

        // Create the address using the API
        const response = await api.createAddresses(null, [newAddress]);

        // Handle a successful response
        this.isSuccessful = true;
        console.log("address response: ", response);

        // const address = response.data.result.records[0];
        this.reloadVendors();
      } catch (error) {
        console.error("Error creating address", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    reloadVendors() {
      this.$emit("reloadVendors");
    },
    handleSuccess(groupId) {
      this.$router.push({ name: "RecipientGroupDetails", params: { groupId } });
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again.";
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage =
          "An error occurred setting up the request that prevented it from being sent. Please try again.";
      }

      this.$store.dispatch("notifications/notifyError", errorMessage);
      console.error(errorMessage);
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
