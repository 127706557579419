<template>
  <ThePage class="tw-w-full">
    <EditVendorForm :vendorId="vendorId" :vendorList="vendors"></EditVendorForm>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import EditVendorForm from "../../components-v2/vendors/EditVendorForm.vue";

export default {
  name: "EditVendor",
  components: {
    ThePage,
    EditVendorForm,
  },
  data() {
    return {
      vendors: [],
      selectedOption: "",
    };
  },
  created() {
    this.loadVendors();
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  methods: {
    async loadVendors() {
      const api = await this.getBoldClient();

      let filterParam = {};

      const queryParameters = {
        limit: 1000,
        sort: "name",
      };

      try {
        // Fetch kits using the constructed filterParam

        const selection = '_id name'
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          selection: selection,
        });

        // this.vendorsAreLoading = false;
        // this.count = response.data.result.count;
        this.vendors = response.data.result.records;
      } catch (error) {
        // Handle errors
        this.vendorsAreLoading = false;
        console.log("vendors did not load", error);
      }
    },
  },
};
</script>

<style scoped></style>
