<template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <PageHeader :showActions="true" title="Vendors">
    <template #page-header-actions>
      <TheButton
        :isRouterLink="true"
        to="/vendor/add"
        variant="primary"
        size="large"
        :hasLeadingIcon="true"
      >
        <template #text>Add vendor</template>
        <template #leading-icon>
          <b-icon-plus-lg></b-icon-plus-lg>
        </template>
      </TheButton>
      <TheButton
        type="button"
        :hasLeadingIcon="true"
        variant="tertiary"
        size="large"
        @click="toggleBulkImportModal()"
      >
        <template #text>Import vendors</template>
        <template #leading-icon><b-icon-upload></b-icon-upload></template>
      </TheButton>
    </template>
  </PageHeader>
  <ThePage :fullWidth="true">
    <VendorsIndexTable ref="vendorsIndexTableRef"></VendorsIndexTable>
  </ThePage>
</template>

<script>
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import TheButton from "../../components-v2/ui/actions/TheButton";
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import VendorsIndexTable from "../../components-v2/vendors/VendorsIndexTable.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
// import MapView from "../../components-v2/map/MapView.vue";

export default {
  name: "ViewVendors",
  components: {
    // MapView,
    ThePage,
    PageHeader,
    TheButton,
    NotificationMessage,
    VendorsIndexTable,
  },
  data() {
    return {
      campaignId: null,
      creatingVendor: false,
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async toggleBulkImportModal() {
      await this.$nextTick();
      if (this.$refs.vendorsIndexTableRef) {
        await this.$refs.vendorsIndexTableRef.toggleBulkImportModal();
      }
    },
  },
};
</script>

<style scoped></style>
