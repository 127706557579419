<template>
  <template v-if="giftView">
    <div
      class="tw-bg-stone-100 tw-p-4 tw-rounded-lg tw-shadow-stone-300 tw-shadow-lg tw-w-full"
    >
      <BlockStack :gap="400">
        <template
          v-if="campaign?.metadata?.landingPageSettings?.organizationLogo?.show"
        >
          <template v-if="existingOrganizationLogoMediaFiles?.length > 0">
            <div
              class="tw-h-24 tw-w-48 tw-mx-auto tw-overflow-hidden tw-rounded"
            >
              <img
                class="tw-h-full tw-w-full tw-object-contain"
                :src="existingOrganizationLogoMediaFiles[0].url"
                alt="Organization Logo"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="tw-h-24 tw-w-48 tw-mx-auto tw-overflow-hidden tw-rounded"
            >
              <img
                class="tw-h-full tw-w-full tw-object-contain"
                src="../../../assets/svg/illustrations-test/13_gift_box.png"
                alt="Organization logo"
              />
            </div>
          </template>
        </template>
        <template v-else>
          <div
            class="tw-h-28 tw-min-h-28 tw-w-28 tw-min-w-28 tw-mx-auto tw-overflow-hidden tw-rounded"
          >
            <img
              class="tw-h-full tw-w-full tw-object-contain"
              src="../../../assets/svg/illustrations-test/13_gift_box.png"
              alt="Organization logo"
            />
          </div>
        </template>

        <TheBox :noHorizontalPadding="true">
          <BlockStack :gap="800" class="sm:tw-px-5">
            <h2 class="tw-text-2xl tw-text-center tw-m-0">
              {{ campaign?.metadata?.landingPageSettings?.giftNote?.title }}
            </h2>
            <div class="tw-flex tw-justify-center tw-w-full">
              <div
                class="tw-min-h-fit tw-w-full tw-rounded-md tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-text-lg"
                v-html="
                  this.decodeHtml(
                    campaign?.metadata?.landingPageSettings?.giftNote?.message
                  )
                "
              ></div>
            </div>
          </BlockStack>
        </TheBox>
        <div
          class="tw-w-full tw-border-solid tw-border-[0.5px] tw-border-gray-300"
        ></div>
        <TheBox :noHorizontalPadding="true">
          <div
            class="tw-flex tw-flex-col tw-items-center tw-gap-4 sm:tw-flex-row"
          >
            <template
              v-if="
                campaign?.metadata?.landingPageSettings?.signature
                  ?.profilePhoto?.show
              "
            >
              <template v-if="existingProfilePhotoMediaFiles?.length > 0">
                <div
                  class="tw-h-28 tw-min-h-28 tw-w-28 tw-min-w-28 tw-rounded-full"
                >
                  <img
                    class="tw-h-full tw-w-full tw-rounded-full tw-flex-none tw-object-cover tw-border-solid tw-border-[0.5px]"
                    :src="existingProfilePhotoMediaFiles[0].url"
                    alt="Profile Photo"
                  />
                </div>
              </template>
            </template>
            <BlockStack :gap="100" class="tw-px-5 tw-py-3">
              <p class="tw-m-0 tw-text-lg tw-font-bold">
                <template
                  v-if="
                    campaign?.metadata?.landingPageSettings?.signature
                      ?.displayName?.value
                  "
                >
                  {{
                    campaign?.metadata?.landingPageSettings?.signature
                      ?.displayName?.value
                  }}
                </template>
                <template v-else>
                  {{ campaign?.from?.name }}
                </template>
              </p>

              <template
                v-if="
                  campaign?.metadata?.landingPageSettings?.signature?.jobTitle
                    .show &&
                  campaign?.metadata?.landingPageSettings?.signature?.jobTitle
                    .value
                "
              >
                <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                  {{
                    campaign?.metadata?.landingPageSettings?.signature?.jobTitle
                      .value
                  }}
                  <template
                    v-if="
                      campaign?.metadata?.landingPageSettings?.signature
                        ?.organizationName.show &&
                      campaign?.metadata?.landingPageSettings?.signature
                        ?.organizationName.value
                    "
                  >
                    at
                    {{
                      campaign?.metadata?.landingPageSettings?.signature
                        ?.organizationName.value
                    }}</template
                  >
                </p>
              </template>

              <template
                v-if="
                  (!campaign?.metadata?.landingPageSettings?.signature?.jobTitle
                    .show ||
                    !campaign?.metadata?.landingPageSettings?.signature
                      ?.jobTitle.value) &&
                  campaign?.metadata?.landingPageSettings?.signature
                    ?.organizationName.show &&
                  campaign?.metadata?.landingPageSettings?.signature
                    ?.organizationName.value
                "
              >
                <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                  {{
                    campaign?.metadata?.landingPageSettings?.signature
                      ?.organizationName.value
                  }}
                </p>
              </template>

              <template
                v-if="
                  campaign?.metadata?.landingPageSettings?.signature
                    ?.accountEmail.show
                "
              >
                <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                  <template
                    v-if="
                      campaign?.metadata?.landingPageSettings?.signature
                        ?.organizationName.value
                    "
                  >
                    {{
                      campaign?.metadata?.landingPageSettings?.signature
                        ?.accountEmail?.value
                    }}
                  </template>

                  <template v-else>
                    {{ campaign?.from?.email }}
                  </template>
                </p>
              </template>
              <template
                v-if="
                  campaign?.metadata?.landingPageSettings?.signature
                    ?.aboutDescription?.show &&
                  campaign?.metadata?.landingPageSettings?.signature
                    ?.aboutDescription?.value
                "
              >
                <div
                  class="tw-w-full tw-border-solid tw-border-[0.5px] tw-border-gray-300 tw-my-5"
                ></div>

                <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                  {{
                    campaign?.metadata?.landingPageSettings?.signature
                      ?.aboutDescription?.value
                  }}
                </p>
              </template>
            </BlockStack>
          </div>
        </TheBox>
      </BlockStack>
    </div>
    <div :withPadding="true" class="tw-flex tw-justify-center tw-mt-6">
      <TheButton
        variant="secondary"
        size="regular"
        @click="closeModal()"
        :hasTrailingIcon="true"
      >
        <template #text> Pick your gift </template>
        <template #trailing-icon> <b-icon-arrow-right /> </template>
      </TheButton>
    </div>
  </template>
  <template v-if="accountSettingsPreview">
    <div
      class="tw-bg-stone-100 tw-p-4 tw-rounded-lg tw-shadow-stone-300 tw-shadow-lg tw-w-full"
    >
      <BlockStack :gap="400">
        <template
          v-if="
            userMixinData.metadata.landingPageSettings.organizationLogo.show
          "
        >
          <template v-if="existingOrganizationLogoMediaFiles?.length > 0">
            <div
              class="tw-h-24 tw-w-48 tw-mx-auto tw-overflow-hidden tw-rounded"
            >
              <img
                class="tw-h-full tw-w-full tw-object-contain"
                :src="existingOrganizationLogoMediaFiles[0].url"
                alt="Organization Logo"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="tw-h-24 tw-w-48 tw-mx-auto tw-overflow-hidden tw-rounded"
            >
              <img
                class="tw-h-full tw-w-full tw-object-contain"
                src="../../../assets/svg/illustrations-test/13_gift_box.png"
                alt="Organization logo"
              />
            </div>
          </template>
        </template>
        <template v-else>
          <div
            class="tw-h-28 tw-min-h-28 tw-w-28 tw-min-w-28 tw-mx-auto tw-overflow-hidden tw-rounded"
          >
            <img
              class="tw-h-full tw-w-full tw-object-contain"
              src="../../../assets/svg/illustrations-test/13_gift_box.png"
              alt="Organization logo"
            />
          </div>
        </template>

        <TheBox :noHorizontalPadding="true">
          <BlockStack
            :gap="800"
            class="tw-border-dashed tw-border-2 tw-p-10 tw-border-gray-400 tw-bg-yellow-50"
          >
            <h2 class="tw-text-2xl tw-text-center tw-m-0">[GIFT NOTE TITLE]</h2>
            <div class="tw-flex tw-justify-center tw-w-full">
              <p
                class="tw-text-gray-700 tw-font-semibold tw-text-lg tw-text-left tw-m-0"
              >
                "[GIFT NOTE MESSAGE]."
              </p>
            </div>
          </BlockStack>
        </TheBox>
        <div
          class="tw-w-full tw-border-solid tw-border-[0.5px] tw-border-gray-300"
        ></div>
        <TheBox :noHorizontalPadding="true">
          <div
            class="tw-flex tw-flex-col tw-items-center tw-gap-4 sm:tw-flex-row"
          >
            <template
              v-if="
                userMixinData?.metadata?.landingPageSettings?.signature
                  ?.profilePhoto?.show
              "
            >
              <template v-if="existingProfilePhotoMediaFiles?.length > 0">
                <div class="tw-h-28 tw-min-h-28 tw-w-28 tw-min-w-28 tw-rounded-full">
                  <img
                    class="tw-h-full tw-w-full tw-rounded-full tw-object-cover tw-border-solid tw-border-[0.5px]"
                    :src="existingProfilePhotoMediaFiles[0].url"
                    alt="Profile Photo"
                  />
                </div>
              </template>
            </template>
            <BlockStack
              :gap="100"
              class="tw-border-dashed tw-border-2 tw-px-5 tw-py-3 tw-border-gray-400 tw-bg-yellow-50"
            >
              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.displayName?.value
                "
              >
                <p class="tw-m-0 tw-text-lg tw-font-bold">
                  {{
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.displayName?.value
                  }}
                </p>
              </template>

              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.jobTitle?.show
                "
              >
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.jobTitle?.value
                  "
                >
                  <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                    {{
                      userMixinData?.metadata?.landingPageSettings?.signature
                        ?.jobTitle?.value
                    }}
                    <template
                      v-if="
                        userMixinData?.metadata?.landingPageSettings?.signature
                          ?.organizationName?.show
                      "
                      ><template
                        v-if="
                          userMixinData?.metadata?.landingPageSettings
                            ?.signature?.organizationName?.value
                        "
                        >at
                        {{
                          userMixinData?.metadata?.landingPageSettings
                            ?.signature?.organizationName?.value
                        }}</template
                      ></template
                    >
                  </p>
                </template>
              </template>

              <template
                v-if="
                  !userMixinData?.metadata?.landingPageSettings?.signature
                    ?.jobTitle?.show &&
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.organizationName?.show
                "
              >
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.organizationName?.value
                  "
                >
                  <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                    {{
                      userMixinData?.metadata?.landingPageSettings?.signature
                        ?.organizationName?.value
                    }}
                  </p>
                </template>
              </template>

              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.accountEmail?.show
                "
              >
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.accountEmail?.value
                  "
                >
                  <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                    {{
                      userMixinData?.metadata?.landingPageSettings?.signature
                        ?.accountEmail?.value
                    }}
                  </p>
                </template>
              </template>

              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.websiteUrl?.show
                "
              >
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.websiteUrl?.value
                  "
                >
                  <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                    {{
                      userMixinData?.metadata?.landingPageSettings?.signature
                        ?.websiteUrl?.value
                    }}
                  </p>
                </template>
              </template>

              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.aboutDescription?.show &&
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.aboutDescription?.value
                "
              >
                <div
                  class="tw-w-full tw-border-solid tw-border-[0.5px] tw-border-gray-300 tw-my-5"
                ></div>

                <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                  {{
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.aboutDescription?.value
                  }}
                </p>
              </template>
            </BlockStack>
          </div>
        </TheBox>
      </BlockStack>
    </div>
    <div :withPadding="true" class="tw-flex tw-justify-center tw-mt-6">
      <TheButton
        variant="secondary"
        size="regular"
        @click="closeModal()"
        :hasTrailingIcon="true"
      >
        <template #text> Pick your gift </template>
        <template #trailing-icon> <b-icon-arrow-right /> </template>
      </TheButton>
    </div>
  </template>
  <template v-if="campaignView">
    <div
      class="tw-bg-stone-100 tw-p-4 tw-rounded-lg tw-shadow-stone-300 tw-shadow-lg tw-w-full"
    >
      <BlockStack :gap="400">
        <template
          v-if="
            userMixinData?.metadata?.landingPageSettings?.organizationLogo?.show
          "
        >
          <template v-if="existingOrganizationLogoMediaFiles?.length > 0">
            <div
              class="tw-h-24 tw-w-48 tw-mx-auto tw-overflow-hidden tw-rounded"
            >
              <img
                class="tw-h-full tw-w-full tw-object-contain"
                :src="existingOrganizationLogoMediaFiles[0].url"
                alt="Organization Logo"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="tw-h-24 tw-w-48 tw-mx-auto tw-overflow-hidden tw-rounded"
            >
              <img
                class="tw-h-full tw-w-full tw-object-contain"
                src="../../../assets/svg/illustrations-test/13_gift_box.png"
                alt="Organization logo"
              />
            </div>
          </template>
        </template>
        <template v-else>
          <div
            class="tw-h-28 tw-min-h-28 tw-w-28 tw-min-w-28 tw-mx-auto tw-overflow-hidden tw-rounded"
          >
            <img
              class="tw-h-full tw-w-full tw-object-contain"
              src="../../../assets/svg/illustrations-test/13_gift_box.png"
              alt="Organization logo"
            />
          </div>
        </template>

        <TheBox :noHorizontalPadding="true">
          <BlockStack :gap="800" class="sm:tw-px-5">
            <h2 class="tw-text-2xl tw-text-center tw-m-0">
              {{ getCampaignLandingPageTitle }}
            </h2>
            <div class="tw-flex tw-justify-center tw-w-full">
              <div
                class="tw-min-h-fit tw-w-full tw-rounded-md tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-text-lg"
                v-html="this.decodeHtml(this.getCampaignLandingPageMessage)"
              ></div>
            </div>
          </BlockStack>
        </TheBox>
        <div
          class="tw-w-full tw-border-solid tw-border-[0.5px] tw-border-gray-300"
        ></div>
        <TheBox :noHorizontalPadding="true">
          <div
            class="tw-flex tw-flex-col tw-items-center tw-gap-4 sm:tw-flex-row"
          >
            <template
              v-if="
                userMixinData?.metadata?.landingPageSettings?.signature
                  ?.profilePhoto?.show
              "
            >
              <template v-if="existingProfilePhotoMediaFiles?.length > 0">
                <div
                  class="tw-h-28 tw-min-h-28 tw-w-28 tw-min-w-28 tw-rounded-full"
                >
                  <img
                    class="tw-h-full tw-w-full tw-rounded-full tw-flex-none tw-object-cover tw-border-solid tw-border-[0.5px]"
                    :src="existingProfilePhotoMediaFiles[0].url"
                    alt="Profile Photo"
                  />
                </div>
              </template>
            </template>
            <BlockStack :gap="100" class="tw-px-5 tw-py-3">
              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.displayName?.value
                "
              >
                <p class="tw-m-0 tw-text-lg tw-font-bold">
                  {{
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.displayName?.value
                  }}
                </p>
              </template>

              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.jobTitle?.show
                "
              >
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.jobTitle?.value
                  "
                >
                  <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                    {{
                      userMixinData?.metadata?.landingPageSettings?.signature
                        ?.jobTitle?.value
                    }}
                    <template
                      v-if="
                        userMixinData?.metadata?.landingPageSettings?.signature
                          ?.organizationName?.show
                      "
                      ><template
                        v-if="
                          userMixinData?.metadata?.landingPageSettings
                            ?.signature?.organizationName?.value
                        "
                        >at
                        {{
                          userMixinData?.metadata?.landingPageSettings
                            ?.signature?.organizationName?.value
                        }}</template
                      ></template
                    >
                  </p>
                </template>
              </template>

              <template
                v-if="
                  !userMixinData?.metadata?.landingPageSettings?.signature
                    ?.jobTitle?.show &&
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.organizationName?.show
                "
              >
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.organizationName?.value
                  "
                >
                  <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                    {{
                      userMixinData?.metadata?.landingPageSettings?.signature
                        ?.organizationName?.value
                    }}
                  </p>
                </template>
              </template>

              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.accountEmail?.show
                "
              >
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.accountEmail?.value
                  "
                >
                  <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                    {{
                      userMixinData?.metadata?.landingPageSettings?.signature
                        ?.accountEmail?.value
                    }}
                  </p>
                </template>
              </template>

              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.websiteUrl?.show
                "
              >
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.websiteUrl?.value
                  "
                >
                  <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                    {{
                      userMixinData?.metadata?.landingPageSettings?.signature
                        ?.websiteUrl?.value
                    }}
                  </p>
                </template>
              </template>

              <template
                v-if="
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.aboutDescription?.show &&
                  userMixinData?.metadata?.landingPageSettings?.signature
                    ?.aboutDescription?.value
                "
              >
                <div
                  class="tw-w-full tw-border-solid tw-border-[0.5px] tw-border-gray-300 tw-my-5"
                ></div>

                <p class="tw-m-0 tw-font-semibold tw-text-gray-600">
                  {{
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.aboutDescription?.value
                  }}
                </p>
              </template>
            </BlockStack>
          </div>
        </TheBox>
      </BlockStack>
    </div>
    <div :withPadding="true" class="tw-flex tw-justify-center tw-mt-6">
      <TheButton
        variant="secondary"
        size="regular"
        @click="closeModal()"
        :hasTrailingIcon="true"
      >
        <template #text> Pick your gift </template>
        <template #trailing-icon> <b-icon-arrow-right /> </template>
      </TheButton>
    </div>
  </template>
</template>

<script>
import TheBox from "../../../components-v2/ui/layout/TheBox";
import BlockStack from "../../../components-v2/ui/layout/BlockStack";
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";
import userMediaMixin from "@/mixins/userMediaMixin";
import userMixin from "@/mixins/userMixin";

export default {
  name: "LandingPageContent",
  emits: ["closeModal"],
  components: {
    BlockStack,
    TheButton,
    TheBox,
  },
  mixins: [userMediaMixin, userMixin],
  data() {
    return {
      giftNoteMessage: "",
    };
  },
  props: {
    campaign: {
      type: Object,
      default: () => ({}),
    },
    campaignView: {
      type: Boolean,
      default: false,
    },
    getCampaignLandingPageTitle: {
      type: String,
    },
    getCampaignLandingPageData: {
      type: Object,
      default: () => ({}),
    },
    getCampaignLandingPageMessage: {
      type: String,
      default: null,
    },
    dashboardView: {
      type: Boolean,
      default: false,
    },
    giftView: {
      type: Boolean,
    },
    editMyRecordsOnly: {
      type: Boolean,
    },
    accountSettingsPreview: {
      type: Boolean,
    },
  },
  async created() {},
  computed: {
    Avatar() {
      const url = this?.User?.photo;
      if (url) {
        if (url.indexOf("/") === 0) {
          return process.env.VUE_APP_API_BASE_URL + url;
        } else {
          return url;
        }
      } else {
        return `https://api.dicebear.com/7.x/initials/svg?seed=${this?.User?.firstName}%20${this?.User?.lastName}&backgroundColor=3949ab`;
      }
    },
    universalTitle() {
      return this?.userMixinData?.metadata?.landingPageSettings?.gifNote?.title;
    },

    userId() {
      return this?.$store?.state?.auth?.user;
    },
    userFirstName() {
      return this?.userMixinData?.firstName;
    },
    userLastName() {
      return this?.userMixinData?.lastName;
    },
    userEmail() {
      return this?.userMixinData?.email;
    },
    userJobTitle() {
      return this?.userMixinData?.jobTitle;
    },
    userCompany() {
      return this?.userMixinData?.company;
    },
    landingPageTitle() {
      return this?.campaign?.metadata?.landingPage?.title;
    },
    landingPageMessage() {
      return this?.campaign?.metadata?.landingPage?.message;
    },
    landingPageTooltipContent() {
      return "When this is enabled, your gift recipients will see a custom gift note from you when they land on the Bold Xchange app. This is disabled by default.";
    },
    giftNoteTooltipContent() {
      return "You can edit or customize this for each campaign, or use the default to save time and maintain a cohesive tone across similar campaigns.";
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async handleCreatedLifecycleLogic() {
      await this.loadUserProfileMedia();
      if (
        this.getCampaignLandingPageMessage &&
        this.getCampaignLandingPageMessage !== ""
      ) {
        this.giftNoteMessage = this.getCampaignLandingPageMessage;
      }
    },
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    /** handle success and errors */
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      console.error(errorMessage);
    },
    async handleSuccess() {
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: "Profile successfully updated.",
        duration: 6000,
        id: Date.now(),
      });
      this.isEditingDetails = false;
      await this.handleLoadingUserMedia();
    },
  },
};
</script>

<style scoped></style>
