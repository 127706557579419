<template>
  <ButtonDropdown
    :disabled="!isEditingDetails"
    :id="'bulk-update-vendors-to-region-dropdown'"
    :items="regionDropdownList()"
    :buttonText="region ? region : 'Choose a region...'"
    :alignLeft="alignLeft"
    :active="false"
    :hasLeadingIcon="false"
    :hasTrailingIcon="true"
    maxHeight="200px"
    :size="size"
    variant="tertiary"
  />
</template>

<script>
import ButtonDropdown from "../../elements/dropdown/ButtonDropdown.vue";

export default {
  name: "RegionsButtonDropdown",
  emits: ["handleRegionSelection"],
  components: {
    ButtonDropdown,
  },
  props: {
    segmentFilter: {
      type: Object,
      default: () => ({}),
    },
    region: {
      type: String || Object,
      default: null,
    },
    isEditingDetails: {
      type: Boolean,
      default: true,
    },
    alignLeft: {
      type: Boolean,
      default: true,
    },
    isAdminPage: {
      type: Boolean,
    },
    size: {
      type: String,
      default: "large",
    },
  },
  created() {
    this.listRegionAddresses();
    if (this.region) {
      this.selectedRegion = this.region;
    }
    // this.initializeForm(this.segment);
  },
  data() {
    return {
      selectedRegion: null,
      regionAddresses: [],
      loadingRegionAddresses: false, // Add this flag for loading state
    };
  },
  computed: {
    getRegion() {
      return this.region;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    sortedRegionAddresses() {
      const regionAddresses = this.regionAddresses.length;
      return regionAddresses
        ? [...this.regionAddresses].sort((a, b) =>
            a.city.replace(/\./g, "").localeCompare(b.city.replace(/\./g, ""))
          )
        : false;
    },
  },
  methods: {
    async listRegionAddresses() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.loadingRegionAddresses = true;
      this.doesAddressExist = true;

      const pageSize = 300;

      let requestBody = {
        "metadata.isRegionAddress": { $exists: true },
        "metadata.isOnline": { $eq: true },
      };
      if (this.isAdminPage) {
        requestBody = {
          "metadata.isRegionAddress": { $exists: true },
          "metadata.isOnline": { $exists: true },
        };
      }
      let selection = "lines city state postalCode country";
      const queryParameters = {
        limit: pageSize,
        // sort: "city",
      };

      try {
        const response = await api.listAddresses(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        const count = response.data.result.count;
        this.count = count;

        this.regionAddresses = response.data.result.records;
      } catch (error) {
        console.log("addresses did not load", error);
      } finally {
        this.loadingRegionAddresses = false;
      }
    },

    regionDropdownList() {
      if (this.loadingRegionAddresses) {
        return [
          {
            label: "Loading...",
            sublabel: "Please wait while we load your regions.",
          },
        ];
      }
      if (this.regionAddresses.length === 0) {
        return [
          {
            label: "You don't have any regions, yet.",
            sublabel: "Once you create a region, it'll show up here.",
          },
        ];
      }
      return this.sortedRegionAddresses.map((region) => ({
        label: `${region.city}, ${region.state}`,
        // sublabel: `Members: ${group.members.length}`,
        action: () => this.handleRegionSelection(region),
      }));
    },
    handleRegionSelection(region) {
      this.selectedRegion = region.city;
      console.log("handleRegionSelection: ", region);
      this.$emit("handleRegionSelection", region.city);
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
