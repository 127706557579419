<template>
  <AddressListModal
    v-if="isAddressListModalVisible"
    @cancel="showAddressListModal"
    @setNewAddress="setNewAddress"
  ></AddressListModal>
  <template v-if="showAddressForm">
    <Teleport to="#modal">
      <TheModal
        title="Creating address"
        @closeModal="closeModal"
        @reloadList="reloadList"
        :open="showAddressForm"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <AddressForm
          @closeModal="closeModal"
          @setNewAddress="setNewAddress"
        ></AddressForm>
      </TheModal>
    </Teleport>
  </template>

  <BlockStack :gap="400" class="tw-w-full">
    <NotificationMessage :scope="$options.name"></NotificationMessage>
    <template v-if="isLoading">
      <PageSection class="tw-w-full">
        <BlockStack :gap="600" class="tw-w-full">
          <div class="tw-flex tw-bg-white tw-rounded tw-p-4">
            <ListSkeleton :numberOfRows="5"></ListSkeleton>
          </div>
        </BlockStack>
      </PageSection>
    </template>

    <template v-else-if="!isLoading && selectedVendor">
      <BlockStack :gap="400" class="tw-w-full">
        <div class="tw-flex tw-align-end tw-justify-between tw-text-start">
          <h1 class="tw-mb-0">Edit Vendor</h1>
          <div class="tw-flex tw-items-center tw-justify-center tw-gap-1">
            <p
              class="tw-text-red-500 mb-0"
              v-if="!isGeneralInformationComplete"
            >
              General Information section is incomplete.
            </p>
            <template v-if="previousVendorId">
              <TheButton
                :isRouterLink="true"
                variant="ghost"
                size="large"
                :hasLeadingIcon="true"
                :to="{
                  name: 'EditVendor',
                  params: { vendorId: previousVendorId },
                }"
              >
                <template #leading-icon
                  ><b-icon-chevron-left></b-icon-chevron-left
                ></template>
              </TheButton>
            </template>
            <ButtonDropdown
              :id="'vendor-selection-dropdown'"
              :items="vendorListRoutes()"
              :buttonText="`${selectedVendor?.name}`"
              :alignLeft="false"
              :active="false"
              :hasTrailingIcon="true"
              size="regular"
              variant="tertiary"
            >
            </ButtonDropdown>
            <template v-if="nextVendorId">
              <TheButton
                :isRouterLink="true"
                variant="ghost"
                size="large"
                :hasLeadingIcon="true"
                :to="{
                  name: 'EditVendor',
                  params: { vendorId: nextVendorId },
                }"
              >
                <template #leading-icon
                  ><b-icon-chevron-right></b-icon-chevron-right
                ></template>
              </TheButton>
            </template>
            <div class="tw-flex tw-justify-end">
              <TheButton
                :disabled="!isGeneralInformationComplete"
                type="button"
                variant="success"
                size="regular"
                :hasLeadingIcon="true"
                @click.prevent="updateThisVendor()"
              >
                <template #text>
                  <template v-if="vendorIsSaving">Saving...</template>
                  <template v-else>Save changes</template>
                </template>
                <template #leading-icon>
                  <template v-if="vendorIsSaving">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="tw-sr-only">Loading...</span>
                    </div>
                  </template>
                </template>
              </TheButton>
            </div>
          </div>
        </div>
        <BlockStack :gap="600" class="tw-w-full">
          <div class="tw-w-full tw-flex tw-gap-4 tw-flex-wrap">
            <!-- general -->
            <TheCard class="tw-w-1/3 tw-min-w-[500px] tw-flex-1">
              <CardHeader
                :showTitle="true"
                :showSubheading="false"
                :showActions="false"
                :showBorder="true"
                title=" General information"
              >
              </CardHeader>
              <TheBox :withPadding="true">
                <BlockStack :gap="600" class="">
                  <!-- warning -->
                  <template v-if="!isGeneralInformationComplete">
                    <div
                      class="tw-flex tw-items-center tw-gap-1 tw-border-solid tw-border-red-500 tw-border-2 tw-p-4 tw-rounded-md"
                    >
                      <div
                        class="tw-flex tw-items-top tw-gap-2 tw-font-bold tw-text-sm"
                      >
                        <b-icon-exclamation-triangle-fill
                          class="tw-text-red-500 tw-mt-0.5"
                        ></b-icon-exclamation-triangle-fill>
                        <p class="tw-m-0 tw-text-gray-900">
                          Please complete required fields
                        </p>
                      </div>
                    </div>
                  </template>
                  <!-- isOnline -->
                  <BlockStack :gap="200" class="tw-max-w-3xl">
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <label
                        for="vendorStatusSelection"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >Status</label
                      >
                      <TheTooltip
                        :toolTip="true"
                        tooltipContent="Only ONLINE vendors are visible on the customer-facing gift card collection."
                      >
                        <template #icon>
                          <div>
                            <b-icon-exclamation-triangle-fill
                              class="tw-z-0 tw-text-orange-500"
                            ></b-icon-exclamation-triangle-fill>
                          </div>
                        </template>
                      </TheTooltip>
                    </div>
                    <select
                      v-model="selectedVendor.metadata.isOnline"
                      id="vendorStatusSelection"
                      name="vendorStatusSelection"
                      class="tw-w-1/4 tw-block tw-bg-white tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
                    >
                      <option value="" class="" disabled>Select status</option>
                      <option
                        v-for="option in vendorStatusOptions"
                        :value="option.value"
                        :key="option.index"
                        class="tw-capitalize"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </BlockStack>
                  <!-- name -->
                  <BlockStack :gap="200" class="tw-max-w-lg">
                    <label
                      for="vendorName"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Name<span
                        v-if="!getVendorName"
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      ></label
                    >
                    <input
                      v-model="selectedVendor.name"
                      type="text"
                      name="vendorName"
                      id="vendorName"
                      :class="{
                        'tw-ring-red-300': !getVendorName,
                        'tw-ring-green-500': getVendorName,
                      }"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="Bold Xchange"
                    />
                  </BlockStack>
                  <!-- short description -->
                  <BlockStack :gap="100" class="">
                    <label
                      for="vendorShortDescription"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Short description<span
                        v-if="!selectedVendor.metadata.shortDescription"
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      ></label
                    >
                    <textarea
                      rows="1"
                      v-model="selectedVendor.metadata.shortDescription"
                      name="vendorShortDescription"
                      id="vendorShortDescription"
                      placeholder="Vendor short bio"
                      :class="{
                        'tw-ring-red-300':
                          !selectedVendor.metadata.shortDescription,
                        'tw-ring-green-500':
                          selectedVendor.metadata.shortDescription,
                      }"
                      class="tw-block tw-p-4 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                    ></textarea>
                  </BlockStack>
                  <!-- Long Bio -->
                  <BlockStack :gap="100" class="">
                    <label
                      for="vendorDescription"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Long bio<span
                        v-if="!vendorDescription"
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      ></label
                    >
                    <textarea
                      rows="5"
                      v-model="selectedVendor.description"
                      name="vendorDescription"
                      id="vendorDescription"
                      placeholder="Vendor long bio"
                      :class="{
                        'tw-ring-red-300': !vendorDescription,
                        'tw-ring-green-500': vendorDescription,
                      }"
                      class="tw-block tw-p-4 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                    ></textarea>
                  </BlockStack>
                  <!-- gift card page and minimum amount -->
                  <div class="tw-flex gap-2 tw-flex-wrap tw-w-full">
                    <BlockStack :gap="200" class="">
                      <label
                        for="gift_card_minimum"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >Gift card minimum<span
                          v-if="
                            !isGiftCardMinimumValid || !isGiftCardValueTypeValid
                          "
                          class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                          >required</span
                        ></label
                      >
                      <input
                        v-model="selectedVendor.metadata.gift_card_minimum"
                        type="number"
                        name="gift_card_minimum"
                        id="gift_card_minimum"
                        :class="{
                          'tw-ring-red-300':
                            !isGiftCardMinimumValid ||
                            !isGiftCardValueTypeValid,
                          'tw-ring-green-500':
                            isGiftCardMinimumValid && isGiftCardValueTypeValid,
                        }"
                        class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                        placeholder="$$$"
                      />
                    </BlockStack>
                    <BlockStack :gap="200" class="tw-w-1/2">
                      <label
                        for="gift_card_page"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >Gift card page<span
                          v-if="!selectedVendor.metadata.gift_card_page"
                          class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                          >required</span
                        ></label
                      >
                      <input
                        v-model="selectedVendor.metadata.gift_card_page"
                        type="text"
                        name="gift_card_page"
                        id="gift_card_page"
                        :class="{
                          'tw-ring-green-500':
                            selectedVendor.metadata.gift_card_page,
                          'tw-ring-red-300':
                            !selectedVendor.metadata.gift_card_page,
                        }"
                        class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                        placeholder="www.example.com"
                      />
                    </BlockStack>
                  </div>

                  <!-- region -->
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="vendorRegion"
                      class="tw-text-lg tw-leading-6 tw-text-gray-900"
                      >Region<span
                        v-if="!getRegion"
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      ></label
                    >
                    <!-- region addresses -->

                    <!-- {{ selectedVendor.metadata.region }} -->
                    <!-- <template v-if="getRegion"> -->
                    <RegionsButtonDropdown
                      size="regular"
                      :isAdminPage="true"
                      :region="getRegion"
                      :alignLeft="true"
                      @handleRegionSelection="handleRegionSelection"
                    ></RegionsButtonDropdown>
                    <!-- </template> -->

                    <!-- <select
                  v-model="selectedVendor.metadata.region"
                  id="vendorRegion"
                  name="vendorRegion"
                  class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-3 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg"
                  :class="{
                    'tw-ring-green-500': selectedVendor.metadata.region,
                    'tw-ring-red-300': !selectedVendor.metadata.region,
                  }"
                >
                  <option value="" class="" disabled>Select region</option>
                  <option
                    v-for="option in vendorRegionOptions"
                    :value="[option.value]"
                    :key="option.index"
                    class=""
                  >
                    {{ option.text }}
                  </option>
                </select> -->
                  </BlockStack>

                  <!-- categories + platforms -->
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="categories"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Categories<span
                        v-if="
                          !vendorCategories || vendorCategories.length === 0
                        "
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      >
                    </label>
                    <MultiSelectDropdownBase
                      dataType="categories"
                      buttonText="Select categories"
                      v-model="this.selectedVendor.categories"
                      :placeholder="'Select Categories'"
                      size="large"
                      :id="'categories-multiselect-dropdown'"
                    />
                  </BlockStack>
                  <!-- neighborhoods -->
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="neighborhoods"
                      class="tw-text-lg tw-leading-6 tw-text-gray-900"
                      >Neighborhoods</label
                    >
                    <MultiSelectDropdownBase
                      dataType="neighborhoods"
                      buttonText="Select neighborhoods"
                      v-model="this.selectedVendor.metadata.neighborhoods"
                      :placeholder="'Select neighborhoods'"
                      size="large"
                      :id="'neighborhoods-multiselect-dropdown'"
                    />
                  </BlockStack>
                  <!-- tags -->
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="tags"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Tags</label
                    >
                    <MultiSelectDropdownBase
                      dataType="tags"
                      buttonText="Select tags"
                      v-model="this.selectedVendor.metadata.tags"
                      :placeholder="'Select tags'"
                      size="large"
                      :id="'tags-multiselect-dropdown'"
                    />
                  </BlockStack>
                  <!-- gift card platform -->
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="online_platforms"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Gift card platform (Square, Toast, etc.)</label
                    >
                    <MultiSelectDropdownBase
                      dataType="onlineStorePlatforms"
                      buttonText="Select gift card platform"
                      v-model="
                        this.selectedVendor.metadata.onlineStorePlatforms
                      "
                      :placeholder="'Select gift card platform'"
                      size="large"
                      :id="'onlineStorePlatforms-multiselect-dropdown'"
                    />
                  </BlockStack>
                </BlockStack>
              </TheBox>
            </TheCard>

            <BlockStack :gap="600" class="tw-w-2/3 tw-min-w-[500px] tw-flex-1">
              <!-- address -->
              <TheCard class="tw-w-full">
                <CardHeader
                  :showTitle="true"
                  :showSubheading="false"
                  :showActions="true"
                  :showBorder="true"
                  title="Address (optional)"
                >
                  <template #subheading-content>
                    <p class="tw-m-0 tw-text-sm tw-text-gray-800 tw-w-sm">
                      OPTIONAL
                    </p>
                  </template>
                  <template #card-header-actions>
                    <template v-if="vendorAddressId">
                      <template v-if="!this.addressIsBeingEdited">
                        <div class="tw-flex tw-justify-end">
                          <TheButton
                            type="button"
                            :hasLeadingIcon="true"
                            variant="primary"
                            size="regular"
                            @click="editAddress()"
                          >
                            <template #leading-icon
                              ><b-icon-pencil></b-icon-pencil
                            ></template>
                            <template #text>Edit</template>
                          </TheButton>
                        </div>
                      </template>
                      <template v-if="this.addressIsBeingEdited">
                        <div class="tw-flex tw-justify-end tw-gap-2">
                          <TheButton
                            type="button"
                            variant="tertiary"
                            size="regular"
                            @click="editAddress()"
                          >
                            <template #text>Cancel</template>
                          </TheButton>
                          <TheButton
                            type="button"
                            variant="success"
                            size="regular"
                            @click="updateAddress()"
                          >
                            <template #text>
                              <template v-if="addressIsSaving">
                                <span
                                  class="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Saving changes...
                              </template>
                              <template v-else> Save changes </template>
                            </template>
                          </TheButton>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="!this.addressIsBeingEdited">
                        <div class="tw-flex tw-justify-end">
                          <TheButton
                            type="button"
                            :hasLeadingIcon="true"
                            variant="secondary"
                            size="regular"
                            @click="toggleNewAddressModal()"
                          >
                            <template #leading-icon
                              ><b-icon-plus-lg></b-icon-plus-lg
                            ></template>
                            <template #text>Add address</template>
                          </TheButton>
                        </div>
                      </template>
                    </template>
                  </template>
                </CardHeader>
                <template v-if="vendorAddressId">
                  <TheBox :withPadding="true">
                    <BlockStack :gap="600" class="tw-w-full">
                      <!-- warning -->
                      <template v-if="!addressInfoSectionComplete">
                        <div
                          class="tw-flex tw-items-center tw-gap-1 tw-border-solid tw-border-red-500 tw-border-2 tw-p-4 tw-rounded-md"
                        >
                          <div
                            class="tw-flex tw-items-top tw-gap-2 tw-font-bold tw-text-sm"
                          >
                            <b-icon-exclamation-triangle-fill
                              class="tw-text-red-500 tw-mt-0.5"
                            ></b-icon-exclamation-triangle-fill>
                            <p class="tw-m-0 tw-text-gray-900">
                              Please complete required fields
                            </p>
                          </div>
                        </div>
                      </template>
                      <!-- create -->
                      <template v-if="addressIsBeingEdited">
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="createAddress"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >Select/create address</label
                          >
                          <span
                            v-if="doesOnlineStorePlatformExist"
                            class="text-danger medium ms-2"
                            >Gift card platform already exists</span
                          >
                          <span class="tw-isolate tw-inline-flex tw-rounded-md">
                            <button
                              type="button"
                              @click.prevent="showAddressListModal()"
                              class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-flex tw-items-center tw-justify-between tw-grow tw-rounded-l-md tw-bg-white tw-px-3.5 tw-py-2 tw-text-lg tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                            >
                              <span>Select address</span>
                              <span
                                ><b-icon-chevron-down></b-icon-chevron-down
                              ></span>
                            </button>
                            <button
                              type="button"
                              @click.prevent="toggleNewAddressModal()"
                              class="tw-border-none tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed tw-relative tw-rounded-r-md tw-px-2.5 tw-py-2 tw-text-lg focus:tw-z-10"
                            >
                              create
                            </button>
                          </span>
                        </BlockStack>
                      </template>
                      <!-- address -->
                      <div class="tw-flex gap-2 tw-w-full">
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="addressLine1"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >Address line 1</label
                          >
                          <input
                            type="text"
                            name="addressLine1"
                            id="addressLine1"
                            :disabled="!addressIsBeingEdited"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
                            :class="{
                              'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                                !addressIsBeingEdited,
                              'tw-block tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-leading-6':
                                addressIsBeingEdited,
                            }"
                            :readonly="!addressIsBeingEdited"
                            placeholder="Street address, P.O box"
                            aria-label="Street address, P.O box"
                            v-model="selectedVendor.address.lines[0]"
                          />
                        </BlockStack>
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="addressLine2"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >Address line 2</label
                          >
                          <input
                            type="text"
                            name="addressLine2"
                            id="addressLine2"
                            :disabled="!addressIsBeingEdited"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
                            :class="{
                              'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                                !addressIsBeingEdited,
                              'tw-block tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-leading-6':
                                addressIsBeingEdited,
                            }"
                            :readonly="!addressIsBeingEdited"
                            placeholder="Street address, P.O box"
                            aria-label="Street address, P.O box"
                            v-model="selectedVendor.address.lines[1]"
                          />
                        </BlockStack>
                      </div>
                      <div class="tw-flex gap-2 tw-w-full">
                        <!-- city -->
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="city"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >City<span
                              v-if="!selectedVendor.address.city"
                              class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                              >required</span
                            >
                          </label>
                          <input
                            type="text"
                            :disabled="!addressIsBeingEdited"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
                            :class="{
                              'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                                !addressIsBeingEdited,
                              'tw-block tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-leading-6':
                                addressIsBeingEdited,
                            }"
                            :readonly="!addressIsBeingEdited"
                            name="city"
                            id="city"
                            required
                            placeholder="St. Louis"
                            aria-label="St. Louis"
                            v-model="selectedVendor.address.city"
                          />
                        </BlockStack>

                        <!-- state -->
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="state"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >State<span
                              v-if="!isStateValid"
                              class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                              >required</span
                            >
                          </label>
                          <select
                            :disabled="!addressIsBeingEdited"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
                            :class="{
                              'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                                !addressIsBeingEdited,
                              'tw-block tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-leading-6':
                                addressIsBeingEdited,
                            }"
                            :readonly="!addressIsBeingEdited"
                            v-model="selectedVendor.address.state"
                            name="stateSelect"
                            id="stateSelect"
                          >
                            <option value="" class="" disabled>
                              Select state
                            </option>
                            <option
                              v-for="option in stateOptions"
                              :value="option.value"
                              :key="option.index"
                            >
                              {{ option.text }} ({{ option.value }})
                            </option>
                          </select>
                        </BlockStack>
                      </div>
                      <div class="tw-flex gap-2 tw-w-full">
                        <!-- postal -->
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="postalCode"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >Postal Code</label
                          >
                          <input
                            type="text"
                            :disabled="!addressIsBeingEdited"
                            name="postalCode"
                            id="postalCode"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
                            :class="{
                              'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                                !addressIsBeingEdited,
                              'tw-block tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-leading-6':
                                addressIsBeingEdited,
                            }"
                            :readonly="!addressIsBeingEdited"
                            placeholder="11111"
                            aria-label="11111"
                            v-model="selectedVendor.address.postalCode"
                          />
                        </BlockStack>
                        <!-- country -->
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="country"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >Country</label
                          >
                          <input
                            type="url"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
                            :class="{
                              'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                                !addressIsBeingEdited,
                              'tw-block tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-leading-6':
                                addressIsBeingEdited,
                            }"
                            readonly
                            name="country"
                            id="country"
                            required
                            placeholder="country"
                            v-model="selectedVendor.address.country"
                          />
                        </BlockStack>
                      </div>
                    </BlockStack>
                  </TheBox>
                </template>
                <template v-else>
                  <TheBox :withPadding="true">
                    <p class="tw-text-red-500 tw-m-0 tw-text-lg">
                      No address on file.
                    </p>
                  </TheBox>
                </template>
              </TheCard>

              <BlockStack :gap="600" class="tw-flex-1">
                <!-- social media -->
                <TheCard class="tw-grow">
                  <CardHeader
                    :showTitle="true"
                    :showSubheading="false"
                    :showActions="true"
                    :showBorder="true"
                    title="Social Media (optional)"
                  >
                  </CardHeader>

                  <TheBox :withPadding="true">
                    <BlockStack :gap="600" class="tw-w-full">
                      <div class="tw-flex gap-2 tw-w-full">
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="companyWebsite"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            ><b-icon-globe class="tw-mr-1"></b-icon-globe
                            >Website</label
                          >
                          <input
                            type="text"
                            name="companyWebsite"
                            id="companyWebsite"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                            placeholder="Website URL"
                            aria-label="Website URL"
                            v-model="selectedVendor.metadata.website_url"
                          />
                        </BlockStack>
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="linkedinProfile"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            ><b-icon-linkedin class="tw-mr-1"></b-icon-linkedin
                            >LinkedIn</label
                          >
                          <input
                            type="text"
                            name="linkedinProfile"
                            id="linkedinProfile"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                            placeholder="LinkedIn URL"
                            aria-label="LinkedIn URL"
                            v-model="selectedVendor.metadata.linkedin_url"
                          />
                        </BlockStack>
                      </div>
                      <div class="tw-flex gap-2 tw-w-full">
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="twitterProfile"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            ><b-icon-twitter class="tw-mr-1"></b-icon-twitter
                            >Twitter</label
                          >
                          <input
                            type="text"
                            name="twitterProfile"
                            id="twitterProfile"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                            placeholder="Twitter URL"
                            aria-label="Twitter URL"
                            v-model="selectedVendor.metadata.twitter_url"
                          />
                        </BlockStack>
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="tiktokProfile"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            ><b-icon-tiktok class="tw-mr-1"></b-icon-tiktok
                            >TikTok</label
                          >
                          <input
                            type="text"
                            name="tiktokProfile"
                            id="tiktokProfile"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                            placeholder="TikTok URL"
                            aria-label="TikTok URL"
                            v-model="selectedVendor.metadata.tiktok_url"
                          />
                        </BlockStack>
                      </div>
                      <div class="tw-flex gap-2 tw-w-full">
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="instagramProfile"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            ><b-icon-instagram
                              class="tw-mr-1"
                            ></b-icon-instagram
                            >Instagram</label
                          >
                          <input
                            type="text"
                            name="instagramProfile"
                            id="instagramProfile"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                            placeholder="Instagram URL"
                            aria-label="Instagram URL"
                            v-model="selectedVendor.metadata.instagram_url"
                          />
                        </BlockStack>
                      </div>
                    </BlockStack>
                    <!--  -->
                  </TheBox>
                </TheCard>
                <!-- contact -->
                <TheCard class="tw-grow">
                  <CardHeader
                    :showTitle="true"
                    :showSubheading="false"
                    :showActions="true"
                    :showBorder="true"
                    title="Contact (optional)"
                  >
                  </CardHeader>

                  <TheBox :withPadding="true">
                    <BlockStack :gap="600" class="tw-w-full">
                      <div class="tw-flex gap-2 tw-w-full">
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="contactName"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >Name</label
                          >
                          <input
                            type="text"
                            name="contactName"
                            id="contactName"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                            placeholder="Joe Kay"
                            aria-label="Joe Kay"
                            v-model="selectedVendor.contact.name"
                          />
                        </BlockStack>
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="contactTitle"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >Title</label
                          >
                          <input
                            type="text"
                            name="contactTitle"
                            id="contactTitle"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                            placeholder="Chief of Awesomeness"
                            aria-label="Chief of Awesomeness"
                            v-model="selectedVendor.contact.title"
                          />
                        </BlockStack>
                      </div>
                      <div class="tw-flex gap-2 tw-w-full">
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="contactEmail"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >Email</label
                          >
                          <input
                            type="text"
                            name="contactEmail"
                            id="contactEmail"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                            placeholder="contactemail@company.com"
                            aria-label="contactemail@company.com"
                            v-model="selectedVendor.contact.email"
                          />
                        </BlockStack>
                        <BlockStack :gap="200" class="tw-w-1/2">
                          <label
                            for="contactPhone"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >Phone</label
                          >
                          <input
                            type="text"
                            name="contactPhone"
                            id="contactPhone"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                            placeholder="111-111-1111"
                            aria-label="111-111-1111"
                            v-model="selectedVendor.contact.phone"
                          />
                        </BlockStack>
                      </div>
                    </BlockStack>
                    <!--  -->
                  </TheBox>
                </TheCard>
              </BlockStack>
            </BlockStack>
          </div>

          <div class="tw-w-full tw-flex tw-gap-4 flex-wrap">
            <VendorMediaForm
              mediaType="logos"
              :vendor="selectedVendor"
              :vendorId="vendorId"
            />
            <VendorMediaForm
              mediaType="gift cards"
              :vendor="selectedVendor"
              :vendorId="vendorId"
            />
            <VendorMediaForm
              mediaType="vendor branding"
              :vendor="selectedVendor"
              :vendorId="vendorId"
            />

            <!-- <VendorLogoMedia :vendorId="vendorId" />
          <VendorGiftCardMedia :vendorId="vendorId" />
          <VendorLogoMedia :vendorId="vendorId" /> -->
          </div>
        </BlockStack>
      </BlockStack>
    </template>
  </BlockStack>
</template>

<script>
import VendorMediaForm from "../../components-v2/vendors/VendorMediaForm.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import AddressListModal from "../../components/ui/modals/lists/AddressListModal.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import ListSkeleton from "../../components-v2/ui/skeletons/ListSkeleton.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
import ButtonDropdown from "../../components-v2/ui/elements/dropdown/ButtonDropdown";
import RegionsButtonDropdown from "../../components-v2/ui/elements/dropdown/RegionsButtonDropdown";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import MultiSelectDropdownBase from "../../components-v2/ui/elements/dropdown/MultiSelectDropdownBase";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import AddressForm from "../../components-v2/ui/modals/create/AddressForm.vue";

import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardHeader from "../../components-v2/ui/layout/CardHeader.vue";
export default {
  name: "EditVendorForm",
  components: {
    CardHeader,
    TheModal,
    AddressForm,
    TheCard,
    ButtonDropdown,
    VendorMediaForm,
    MultiSelectDropdownBase,
    RegionsButtonDropdown,
    TheTooltip,
    TheBox,
    PageSection,
    ListSkeleton,
    TheButton,
    BlockStack,
    AddressListModal,
    NotificationMessage,
  },
  created() {
    const vendorId = this.vendorId;
    this.getSingleVendorByID(vendorId);
  },
  props: {
    vendorId: {
      type: String,
      required: false,
    },
    vendorList: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      vendors: [],
      categories: [],
      newGiftCardsFiles: [],
      newVendorLogoFiles: [],
      newBrandingFiles: [],
      existingGiftCardsMedia: [],
      existingVendorLogos: [],
      existingBrandingMedia: [],
      files: [],
      vendorStatusOptions: [
        { text: "Offline", value: false },
        { text: "Online", value: true },
      ],
      vendorRegionOptions: [
        { text: "Baltimore", value: "Baltimore" },
        { text: "Birmingham", value: "Birmingham" },
        { text: "Charlotte", value: "Charlotte" },
        { text: "Columbia", value: "Columbia" },

        { text: "Ft. Lauderdale", value: "Ft. Lauderdale" },
        { text: "Kansas City", value: "Kansas City" },
        { text: "Louisville", value: "Louisville" },
        { text: "St. Louis", value: "St. Louis" },
      ],
      selectedOption: "",
      shortDescription: null,
      selectedVendor: null,
      vendorMedia: null,
      noDataPlaceholderText: "No information",
      addressLine1: "",
      addressLine2: "",
      categorySearch: "",
      newCategory: "",
      mediaUrl: "",
      idOfMediaToDelete: "",
      isSuccessful: false,
      isCancelling: false,
      isLoading: false,
      changesMade: false,
      showPreview: false,
      debounce: null,
      isConfirmDeleteModalVisible: false,
      vendorIsSaving: false,
      mediaIsSaving: false,
      mediaUploadSuccess: false,
      addressIsSaving: false,
      showProductFailedMessage: false,
      isVendorListModalVisible: false,
      isCategoryListModalVisible: false,
      isNewCategoryModalVisible: false,
      isAddressListModalVisible: false,
      showAddressForm: false,
      addressIsBeingEdited: false,
      isVendorOnlineStorePlatformListModalVisible: false,
      isNewVendorOnlineStorePlatformModalVisible: false,
      isNewTagModalVisible: false,
      isTagsListModalVisible: false,
      isNewNeighborhoodModalVisible: false,
      isNeighborhoodsListModalVisible: false,
      showTooltip: false,
      doesCategoryExist: false,
      doesTagExist: false,
      doesOnlineStorePlatformExist: false,
      doesNeighborhoodExist: false,
      updateFailed: false,
      stateOptions: [
        { text: "Alabama", value: "AL" },
        { text: "Alaska", value: "AK" },
        { text: "Arizona", value: "AZ" },
        { text: "Arkansas", value: "AR" },
        { text: "California", value: "CA" },
        { text: "Colorado", value: "CO" },
        { text: "Connecticut", value: "CT" },
        { text: "Delaware", value: "DE" },
        { text: "Florida", value: "FL" },
        { text: "Georgia", value: "GA" },
        { text: "Hawaii", value: "HI" },
        { text: "Idaho", value: "ID" },
        { text: "Illinois", value: "IL" },
        { text: "Indiana", value: "IN" },
        { text: "Iowa", value: "IA" },
        { text: "Kansas", value: "KS" },
        { text: "Kentucky", value: "KY" },
        { text: "Louisiana", value: "LA" },
        { text: "Maine", value: "ME" },
        { text: "Maryland", value: "MD" },
        { text: "Massachusetts", value: "MA" },
        { text: "Michigan", value: "MI" },
        { text: "Minnesota", value: "MN" },
        { text: "Mississippi", value: "MS" },
        { text: "Missouri", value: "MO" },
        { text: "Montana", value: "MT" },
        { text: "Nebraska", value: "NE" },
        { text: "Nevada", value: "NV" },
        { text: "New Hampshire", value: "NH" },
        { text: "New Jersey", value: "NJ" },
        { text: "New Mexico", value: "NM" },
        { text: "New York", value: "NY" },
        { text: "North Carolina", value: "NC" },
        { text: "North Dakota", value: "ND" },
        { text: "Ohio", value: "OH" },
        { text: "Oklahoma", value: "OK" },
        { text: "Oregon", value: "OR" },
        { text: "Pennsylvania", value: "PA" },
        { text: "Rhode Island", value: "RI" },
        { text: "South Carolina", value: "SC" },
        { text: "South Dakota", value: "SD" },
        { text: "Tennessee", value: "TN" },
        { text: "Texas", value: "TX" },
        { text: "Utah", value: "UT" },
        { text: "Vermont", value: "VT" },
        { text: "Virginia", value: "VA" },
        { text: "Washington", value: "WA" },
        { text: "West Virginia", value: "WV" },
        { text: "Wisconsin", value: "WI" },
        { text: "Wyoming", value: "WY" },
      ],
    };
  },
  watch: {
    vendorId: {
      handler(newVal, oldVal) {
        this.getSingleVendorByID(this.vendorId);
      },
      immediate: true,
    },
  },
  computed: {
    isGeneralInformationComplete() {
      return (
        this.getVendorName &&
        this.vendorCategories &&
        this.vendorDescription &&
        this.getRegion &&
        this.isGiftCardMinimumValid &&
        this.isGiftCardValueTypeValid &&
        this.getGiftCardPage
      );
    },
    addressInfoSectionComplete() {
      const { city, state, country } = this.selectedVendor.address;
      return (
        (city === "" && state === "" && country === "" && !this.isStateValid) ||
        (city !== "" && state !== "" && country !== "" && this.isStateValid)
      );
    },
    getVendorName() {
      return this?.selectedVendor?.name;
    },
    getRegion() {
      return this?.selectedVendor?.metadata?.region[0];
    },
    getGiftCardPage() {
      return this.selectedVendor.metadata.gift_card_page;
    },
    vendorDescription() {
      return this?.selectedVendor?.description;
    },
    vendorCategories() {
      return this?.selectedVendor?.categories?.length === 0
        ? false
        : this?.selectedVendor?.categories;
    },
    vendorTags() {
      return this?.selectedVendor?.metadata?.tags?.length === 0
        ? false
        : this?.selectedVendor?.metadata?.tags;
    },
    vendorOnlineStorePlatform() {
      return this?.selectedVendor?.metadata?.onlineStorePlatforms?.length === 0
        ? false
        : this?.selectedVendor?.metadata?.onlineStorePlatforms;
    },
    vendorNeighborhoods() {
      return this?.selectedVendor?.metadata?.neighborhoods?.length === 0
        ? false
        : this?.selectedVendor?.metadata?.neighborhoods;
    },
    vendorAddressId() {
      return this?.selectedVendor?.address?._id;
    },
    vendorContactName() {
      return this?.selectedVendor?.contact?.name;
    },
    getNewGiftCardsMedia() {
      if (this.newGiftCardsFiles.length === 0) {
        return false;
      }
      return this.newGiftCardsFiles;
    },
    getNewVendorLogosMedia() {
      if (this.newVendorLogoFiles.length === 0) {
        return false;
      }
      return this.newVendorLogoFiles;
    },
    getNewBrandingMedia() {
      if (this.newBrandingFiles.length === 0) {
        return false;
      }
      return this.newBrandingFiles;
    },
    getExistingGiftCardsMedia() {
      if (this.existingGiftCardsMedia.length === 0) {
        return false;
      }
      return this.existingGiftCardsMedia;
    },
    getExistingVendorMediaLogos() {
      if (this.existingVendorLogos.length === 0) {
        return false;
      }
      return this.existingVendorLogos;
    },
    getExistingBrandingMedia() {
      if (this.existingBrandingMedia.length === 0) {
        return false;
      }
      return this.existingBrandingMedia;
    },
    getVendorSearchQuery() {
      return this.vendorSearchQuery;
    },
    nextVendorId() {
      const currentIndex = this.vendorList.findIndex(
        (vendor) => vendor.id === this.vendorId
      );

      if (currentIndex < this.vendorList.length - 1) {
        const nextVendorId = this.vendorList[currentIndex + 1].id;
        return nextVendorId;
      } else {
        return null;
      }
    },
    previousVendorId() {
      const currentIndex = this.vendorList.findIndex(
        (vendor) => vendor.id === this.vendorId
      );

      if (currentIndex > 0) {
        const previousVendorId = this.vendorList[currentIndex - 1].id;
        return previousVendorId;
      } else {
        return null;
      }
    },
    isGiftCardMinimumValid() {
      const minValue = 1;
      return (
        parseFloat(this.selectedVendor.metadata.gift_card_minimum) >= minValue
      );
    },
    isGiftCardValueTypeValid() {
      return typeof this.selectedVendor.metadata.gift_card_minimum == "number";
    },
    isOnline() {
      if (this?.selectedVendor?.metadata?.isOnline !== undefined) {
        return this?.selectedVendor?.metadata?.isOnline;
      }
      return false;
    },
    isStateValid() {
      return this.stateOptions.some(
        (state) => state.value === this.selectedVendor.address.state
      );
    },
    regionOptions() {
      const options = this.regions.map((tag) => ({
        text: tag,
        value: tag,
      }));
      return options;
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async getSingleVendorByID(vendorId) {
      // console.log("Route Updated vendorId:", vendorId);
      const api = await this.getBoldClient();
      this.isLoading = true;

      const {
        data: {
          result: { records: singleVendorById },
        },
      } = await api.listVendors(
        {
          limit: 1,
          expand: "media contact address",
        },
        { filter: { _id: vendorId } }
      );

      this.handleVendorData(singleVendorById[0]);
    },
    async reloadVendorData() {
      const api = await this.getBoldClient();
      const {
        data: {
          result: { records: singleVendorById },
        },
      } = await api.listVendors(
        {
          limit: 1,
          expand: "media contact address",
        },
        { filter: { _id: this.vendorId } }
      );

      this.handleVendorData(singleVendorById[0]);
    },
    async updateThisVendor() {
      this.vendorIsSaving = true;
      try {
        const api = await this.getBoldClient();
        const patchObject = this.getPatchObject();

        const {
          data: { result },
        } = await api.upsertVendor(null, {
          filter: { _id: this.vendorId },
          patch: patchObject,
        });

        this.handleVendorData(result);
        this.handleSuccess("upsertVendor");
      } catch (error) {
        this.handleErrorFromAPI(error);
      } finally {
        this.vendorIsSaving = false;
      }
    },
    async updateAddress() {
      this.addressIsSaving = true;
      this.addressIsBeingEdited = false;

      try {
        // Get the Bold client
        const api = await this.getBoldClient();

        // Prepare address lines
        const addressLines = [];
        if (this?.selectedVendor?.address?.lines[0]) {
          addressLines.push(this.selectedVendor.address.lines[0]);
        }
        if (this?.selectedVendor?.address?.lines[1]) {
          addressLines.push(this.selectedVendor.address.lines[1]);
        }

        // Create an address object
        let newAddress = {
          city: this.selectedVendor.address.city,
          state: this.selectedVendor.address.state,
          postalCode: this.selectedVendor.address.postalCode,
          country: this.selectedVendor.address.country,
        };

        // Conditionally add the 'lines' property
        if (addressLines.length > 0) {
          newAddress.lines = addressLines;
        }

        // Create the address using the API
        const response = await api.upsertAddress(null, {
          filter: {
            _id: this.selectedVendor.address._id,
          },
          patch: newAddress,
        });

        // Handle a successful response
        // console.log("address updated", response);
        this.addressIsSaving = false;

        const address = response.data.result.records[0];
        this.setNewAddress(address);
      } catch (error) {
        console.error("Error updating address", error);

        // Handle the error as needed
        console.log("address was NOT created");
      }
    },
    getPatchObject() {
      let filteredContact = this.filterTruthyProperties(
        this.selectedVendor.contact
      );
      const patchObject = {
        name: this.selectedVendor.name,
        categories: this.selectedVendor.categories,
        description: this.selectedVendor.description,
        "contact.name": filteredContact.name,
        "contact.email": filteredContact.email,
        "contact.title": filteredContact.title,
        "contact.phone": filteredContact.phone,
        "metadata.shortDescription":
          this.selectedVendor.metadata.shortDescription,
        "metadata.website_url": this.selectedVendor.metadata.website_url,
        "metadata.twitter_url": this.selectedVendor.metadata.twitter_url,
        "metadata.linkedin_url": this.selectedVendor.metadata.linkedin_url,
        "metadata.tiktok_url": this.selectedVendor.metadata.tiktok_url,
        "metadata.instagram_url": this.selectedVendor.metadata.instagram_url,
        "metadata.region": this.selectedVendor.metadata.region,
        "metadata.isOnline": this.selectedVendor.metadata.isOnline,

        "metadata.onlineStorePlatforms":
          this.selectedVendor.metadata.onlineStorePlatforms,
        "metadata.gift_card_page": this.selectedVendor.metadata.gift_card_page,
        "metadata.tags": this.selectedVendor.metadata.tags,
        "metadata.neighborhoods": this.selectedVendor.metadata.neighborhoods,
        "metadata.gift_card_minimum":
          this.selectedVendor.metadata.gift_card_minimum,
        "metadata.typeform_url": this.selectedVendor.metadata.typeform_url,
      };

      // console.log(this?.selectedVendor?.address?._id || "no address");
      if (
        this?.selectedVendor?.address?._id ||
        this?.selectedVendor?.address === null
      ) {
        if (this?.selectedVendor?.address?._id) {
          patchObject.address = this.selectedVendor.address._id;
        } else {
          patchObject.address = null; // If address is null
        }
      }

      return patchObject;
    },
    filterTruthyProperties(obj) {
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] && typeof obj[key] === "object") {
          const nested = this.filterTruthyProperties(obj[key]);
          if (Object.keys(nested).length > 0) {
            acc[key] = nested;
          }
        } else if (obj[key]) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    },
    handleVendorData(vendorData) {
      // console.log("vendor", vendorData);
      this.selectedVendor = this.extractVendorDetails(vendorData);
      this.vendorMedia = vendorData?.media || [];
      // const mediaArr = vendorData?.media || [];

      // const logos = this.filterMediaByTag(mediaArr, "logos");
      // const giftCardsMedia = this.filterMediaByTag(mediaArr, "gift cards");
      // const brandingMedia = this.filterMediaByTag(mediaArr, "vendor branding");

      // this.createMediaUrls(logos, this.existingVendorLogos, api);
      // this.createMediaUrls(giftCardsMedia, this.existingGiftCardsMedia, api);
      // this.createMediaUrls(brandingMedia, this.existingBrandingMedia, api);

      this.setupDeepWatchers(this.selectedVendor);
      this.changesMade = false;
      this.isLoading = false;
    },
    extractVendorDetails(vendorData) {
      const {
        name = "",
        description = "",
        categories = [],
        media = [],
        contact: {
          name: contactName = "",
          title = "",
          email = "",
          phone = "",
        } = {},
        address,
        metadata: {
          shortDescription = "",
          website_url = "",
          twitter_url = "",
          linkedin_url = "",
          tiktok_url = "",
          instagram_url = "",
          gift_card_page = "",
          gift_card_minimum = "",
          // typeform_url = "",
          // region = [], // original
          region = {},

          isOnline = false,
          tags = [],
          onlineStorePlatforms = [],
          neighborhoods = [],
        } = {},
      } = vendorData;

      if (!address || !Object.keys(address).length) {
        return {
          name,
          description,
          categories,
          media,
          contact: { name: contactName, title, email, phone },
          metadata: {
            shortDescription,
            website_url,
            twitter_url,
            linkedin_url,
            tiktok_url,
            instagram_url,
            onlineStorePlatforms,
            gift_card_page,
            gift_card_minimum,
            tags,
            neighborhoods,
            // typeform_url,
            region,
            isOnline,
          },
        };
      }

      // Check if 'lines' exists within 'address' and is an array
      const { lines: addressLines = [] } = address;
      const [line1 = "", line2 = ""] = addressLines;

      const {
        city = "",
        state = "",
        postalCode = "",
        country = "US",
        _id: addressId = "",
      } = address;

      return {
        name,
        description,
        categories,
        media,
        contact: { name: contactName, title, email, phone },
        address: {
          lines: [line1, line2],
          city,
          state,
          postalCode,
          country,
          _id: addressId,
        },
        metadata: {
          shortDescription,
          website_url,
          twitter_url,
          linkedin_url,
          tiktok_url,
          instagram_url,
          gift_card_page,
          gift_card_minimum,
          tags,
          onlineStorePlatforms,
          neighborhoods,
          // typeform_url,
          region,
          isOnline,
        },
      };
    },
    setupArrayWatcher(arr) {
      arr.forEach((item, index) => {
        this.$watch(
          () => arr[index],
          (newValue, oldValue) => {
            if (newValue !== oldValue) {
              this.changesMade = true;
              // console.log(
              //   `Change detected at index ${index}:`,
              //   oldValue,
              //   "->",
              //   newValue
              // );
            }
          }
        );
      });
    },
    setupDeepWatchers(obj) {
      for (const key in obj) {
        if (Array.isArray(obj[key]) && key === "onlineStorePlatforms") {
          // If it's the 'onlineStorePlatforms' array, set up the array watcher
          this.setupArrayWatcher(obj[key]);
        } else if (Array.isArray(obj[key])) {
          // If it's an array (excluding 'onlineStorePlatforms'), set up a shallow watcher
          // This will watch the array reference itself, not the elements inside it
          this.$watch(
            () => obj[key],
            () => {
              this.changesMade = true;
              // console.log(`Change detected in ${key}`);
            }
          );
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          // If the property is an object, set up a deep watcher recursively
          this.setupDeepWatchers(obj[key]);
        } else {
          // Set up a watcher for non-array properties
          this.$watch(
            () => obj[key],
            (newValue, oldValue) => {
              if (newValue !== oldValue) {
                this.changesMade = true;
                // console.log(
                //   `Change detected in ${key}:`,
                //   oldValue,
                //   "->",
                //   newValue
                // );
              }
            }
          );
        }
      }
    },
    handleRegionSelection(region) {
      this.selectedVendor.metadata.region = [region];
      // this.isFormComplete = true;
    },
    vendorListRoutes() {
      return this.vendorList.map((vendor) => ({
        label: `${vendor.name}`,
        isRouterLink: true,
        to: `/vendor/edit/${vendor.id}`,
      }));
    },
    /** media */
    filterMediaByTag(mediaArr, tag) {
      return mediaArr.filter((media) => {
        return media.metadata.tags.includes(tag);
      });
    },
    createMediaUrls(mediaArr, mediaUrlArr, api) {
      if (mediaArr.length === 0) {
        this.mediaUrl = "";
      }

      mediaUrlArr.length = 0;

      for (const media of mediaArr) {
        const mediaId = media._id;
        const mediaUrl = api.utils.getMediaUrl(mediaId);
        mediaUrlArr.push({ _id: mediaId, url: mediaUrl });
      }
    },
    handleLogoMediaUploads(event) {
      // console.log("handleLogoMediaUploads");

      let uploadedFiles = event.target.files;

      for (var i = 0; i < uploadedFiles.length; i++) {
        const uniqueId = Math.random().toString(36).substr(2, 9); // Generate a unique ID
        const fileWithId = {
          id: uniqueId,
          file: uploadedFiles[i],
        };
        this.newVendorLogoFiles.push(fileWithId);
      }
      this.getVendorLogosImagePreviews();
    },
    handleGiftCardsMediaUploads(event) {
      // console.log("handleGiftCardsMediaUploads");
      let uploadedFiles = event.target.files;

      for (var i = 0; i < uploadedFiles.length; i++) {
        const uniqueId = Math.random().toString(36).substr(2, 9); // Generate a unique ID
        const fileWithId = {
          id: uniqueId,
          file: uploadedFiles[i],
        };
        this.newGiftCardsFiles.push(fileWithId);
      }
      this.getGiftCardsImagePreviews();
    },
    handleBrandingMediaUploads(event) {
      // console.log("handleBrandingMediaUploads");
      let uploadedFiles = event.target.files;

      for (var i = 0; i < uploadedFiles.length; i++) {
        const uniqueId = Math.random().toString(36).substr(2, 9); // Generate a unique ID
        const fileWithId = {
          id: uniqueId,
          file: uploadedFiles[i],
        };
        this.newBrandingFiles.push(fileWithId);
      }
      this.getBrandingImagePreviews();
    },
    getVendorLogosImagePreviews() {
      this.newVendorLogoFiles.forEach((file) => {
        if (/\.(jpe?g|png|gif)$/i.test(file.file.name)) {
          let reader = new FileReader();

          reader.onload = () => {
            let imgElement = document.getElementById("image-" + file.id);
            if (imgElement) {
              imgElement.src = reader.result;
            }
          };

          reader.readAsDataURL(file.file);
        }
      });
    },
    getGiftCardsImagePreviews() {
      this.newGiftCardsFiles.forEach((file) => {
        if (/\.(jpe?g|png|gif)$/i.test(file.file.name)) {
          let reader = new FileReader();

          reader.onload = () => {
            let imgElement = document.getElementById("image-" + file.id);
            if (imgElement) {
              imgElement.src = reader.result;
            }
          };

          reader.readAsDataURL(file.file);
        }
      });
    },
    getBrandingImagePreviews() {
      this.newBrandingFiles.forEach((file) => {
        if (/\.(jpe?g|png|gif)$/i.test(file.file.name)) {
          let reader = new FileReader();

          reader.onload = () => {
            let imgElement = document.getElementById("image-" + file.id);
            if (imgElement) {
              imgElement.src = reader.result;
            }
          };

          reader.readAsDataURL(file.file);
        }
      });
    },
    removeUploadedGiftCardsMediaFile(key) {
      this.newGiftCardsFiles.splice(key, 1);
    },
    removeUploadedLogoMediaFile(key) {
      this.newVendorLogoFiles.splice(key, 1);
    },
    removeUploadedBrandingMediaFile(key) {
      this.newBrandingFiles.splice(key, 1);
    },
    returnToVendorList() {
      this.$router.push({ path: "/vendors" });
    },
    //** categories */
    showCreateCategoryModal() {
      this.isNewCategoryModalVisible = true;
    },
    closeCreateCategoryModal() {
      this.isNewCategoryModalVisible = false;
    },
    showCategoryListModal() {
      this.isCategoryListModalVisible = true;
    },
    handleNewCategories(newCategories) {
      // Handle the array of selected categories
      for (const category of newCategories) {
        this.setExistingCategory(category);
      }
      this.isCategoryListModalVisible = false;
    },
    setExistingCategory(category) {
      const existingCategory = this.selectedVendor.categories.find(
        (cat) => cat === category
      );

      if (!existingCategory) {
        this.selectedVendor.categories.push(category);
        this.doesCategoryExist = false;
      } else {
        this.doesCategoryExist = true;
        setTimeout(() => {
          this.doesCategoryExist = false;
        }, 2000);
      }
      this.isNewCategoryModalVisible = false;
    },
    removeCategorySelected(category) {
      let array = this.selectedVendor.categories;
      let index = array.indexOf(category);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    //** tags */
    showCreateTagsModal() {
      this.isNewTagModalVisible = !this.isNewTagModalVisible;
    },
    showTagsListModal() {
      this.isTagsListModalVisible = !this.isTagsListModalVisible;
    },
    handleNewTags(newTags) {
      // Handle the array of selected categories
      for (const tag of newTags) {
        this.setExistingTag(tag);
      }
      this.isNewTagModalVisible = false;
    },
    setExistingTag(tag) {
      const existingStore = this.selectedVendor.metadata.tags.find(
        (s) => s === tag
      );

      if (!existingStore) {
        this.selectedVendor.metadata.tags.push(tag);
        this.doesTagExist = false;
      } else {
        this.doesTagExist = true;
        setTimeout(() => {
          this.doesTagExist = false;
        }, 2000);
      }
      this.isNewTagModalVisible = false;
      this.isTagsListModalVisible = false;
    },
    removeTagsSelected(tag) {
      let array = this.selectedVendor.metadata.tags;
      let index = array.indexOf(tag);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    closeCategoryListModal() {
      this.isCategoryListModalVisible = false;
    },
    closeTagsListModal() {
      this.isTagsListModalVisible = false;
    },
    closeCreateTagModal() {
      this.isNewTagModalVisible = false;
    },
    //** online store platforms */
    showCreateOnlineStorePlatformModal() {
      this.isNewVendorOnlineStorePlatformModalVisible =
        !this.isNewVendorOnlineStorePlatformModalVisible;
    },
    showVendorOnlineStoreListModal() {
      this.isVendorOnlineStorePlatformListModalVisible =
        !this.isVendorOnlineStorePlatformListModalVisible;
    },
    closeOnlineStorePlatformsListModal() {
      this.isVendorOnlineStorePlatformListModalVisible = false;
    },
    closeCreateOnlineStorePlatformModal() {
      this.isNewVendorOnlineStorePlatformModalVisible = false;
    },
    handleNewOnlineStorePlatform(newStores) {
      // Handle the array of selected categories
      for (const store of newStores) {
        this.setExistingOnlineStorePlatform(store);
      }
      this.isVendorOnlineStorePlatformListModalVisible = false;
    },
    setExistingOnlineStorePlatform(store) {
      const existingStore =
        this.selectedVendor.metadata.onlineStorePlatforms.find(
          (s) => s === store
        );

      if (!existingStore) {
        this.selectedVendor.metadata.onlineStorePlatforms.push(store);
        this.doesOnlineStorePlatformExist = false;
      } else {
        this.doesOnlineStorePlatformExist = true;
        setTimeout(() => {
          this.doesOnlineStorePlatformExist = false;
        }, 2000);
      }

      this.isVendorOnlineStorePlatformListModalVisible = false;
      this.isNewVendorOnlineStorePlatformModalVisible = false;
    },
    removeOnlineStorePlatformSelected(store) {
      let array = this.selectedVendor.metadata.onlineStorePlatforms;
      let index = array.indexOf(store);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    /** neighborhoods actions */
    showCreateNeighborhoodModal() {
      this.isNewNeighborhoodModalVisible = !this.isNewNeighborhoodModalVisible;
    },
    closeCreateNeighborhoodModal() {
      this.isNewNeighborhoodModalVisible = false;
    },
    showNeighborhoodsListModal() {
      this.isNeighborhoodsListModalVisible =
        !this.isNeighborhoodsListModalVisible;
    },
    closeNeighborhoodsListModal() {
      this.isNeighborhoodsListModalVisible = false;
    },
    handleNewNeighborhoods(newNeighborhoods) {
      // Handle the array of selected categories
      for (const neighborhood of newNeighborhoods) {
        this.setExistingNeighborhood(neighborhood);
      }
      this.isNeighborhoodsListModalVisible = false;
    },
    setExistingNeighborhood(neighborhood) {
      const existingNeighborhood =
        this.selectedVendor.metadata.neighborhoods.find(
          (n) => n === neighborhood
        );

      if (!existingNeighborhood) {
        this.selectedVendor.metadata.neighborhoods.push(neighborhood);
        this.doesNeighborhoodExist = false;
      } else {
        this.doesNeighborhoodExist = true;
        setTimeout(() => {
          this.doesNeighborhoodExist = false;
        }, 2000);
      }

      this.isNeighborhoodsListModalVisible = false;
      this.isNewNeighborhoodModalVisible = false;
    },
    removeNeighborhoodSelected(neighborhood) {
      let array = this.selectedVendor.metadata.neighborhoods;
      let index = array.indexOf(neighborhood);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    //** addresses */
    async resetAddress() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      api
        .listAddresses(
          {
            limit: 1,
          },
          { filter: { _id: this.selectedVendor.address._id } }
        )
        .then((response) => {
          // console.log("address reset query", response.data.result.records[0]);
          this.selectedVendor.address = response.data.result.records[0];
        })
        .catch((error) => {
          console.log("address reset query failed", error);
        });
    },
    async editAddress() {
      await this.resetAddress();
      this.addressIsBeingEdited = !this.addressIsBeingEdited;
    },
    toggleNewAddressModal() {
      this.showAddressForm = !this.showAddressForm;
    },
    closeModal() {
      this.showAddressForm = false;
    },
    showAddressListModal() {
      this.isAddressListModalVisible = !this.isAddressListModalVisible;
    },
    setNewAddress(address) {
      this.selectedVendor.address = address;
      this.showAddressForm = false;
      this.isAddressListModalVisible = false;
    },
    //** navigation */
    cancelCreate() {
      this.$router.push(`/vendors`);
    },
    closeSuccessModal() {
      window.location.reload();
      this.isSuccessful = false;
    },
    showConfirmDeleteModal(idOfMedia) {
      this.idOfMediaToDelete = idOfMedia;
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    /** error handling */
    handleSuccess(api) {
      let message = `${this.mediaType} successfully uploaded!`;
      if (api === "deleteMedia") {
        message = `${this.mediaType} successfully deleted!`;
        this.isConfirmDeleteModalVisible = false;
      } else if (api === "upsertVendor") {
        message = `Vendor successfully updated!`;
      }
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: message,
        duration: 3000,
        id: Date.now(),
      });
      this.getSingleVendorByID(this.vendorId);
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again.";
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      console.error(errorMessage);
      this.getSingleVendorByID(this.vendorId);
    },
  },
};
</script>

<style scoped>
ul {
  max-height: 200px;
  overflow: auto;
  cursor: pointer;
}

.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
