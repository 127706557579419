<template>
  <div class="tw-relative" ref="dropdown">
    <TheButton
      :disabled="disabled"
      :active="active"
      :variant="variant"
      :size="size"
      @click.prevent="handleToggleDropdown"
      class="tw-relative"
      :hasLeadingIcon="hasLeadingIcon"
      :hasTrailingIcon="hasTrailingIcon"
    >
      <template #leading-icon><b-icon-plus-lg></b-icon-plus-lg></template>
      <template #trailing-icon
        ><b-icon-chevron-down
          :class="[
            isOpen ? 'tw-rotate-180' : '',
            'tw-transition-transform tw-duration-200 tw-ml-auto',
          ]"
        ></b-icon-chevron-down
      ></template>
      <template #text>
        {{ buttonText }}
      </template>
      <template #active>
        <b-icon-check-circle-fill
          class="tw-absolute -tw-top-1 -tw-right-1 tw-h-4 tw-w-4 tw-border-gray-300 tw-text-blue-400 tw-bg-white tw-rounded-full focus:tw-ring-blue-600 tw-z-10"
        ></b-icon-check-circle-fill>
      </template>
    </TheButton>

    <ul
      class="tw-z-[100] tw-max-h-40 tw-overflow-y-auto tw-absolute tw-divide-y tw-divide-solid tw-divide-gray-200 tw-divide-x-0 tw-overflow-hidden tw-mt-2 tw-w-max tw-border-solid tw-border-[0.5px] tw-bg-white tw-rounded-md tw-shadow-lg tw-list-none tw-p-0 tw-m-0"
      :class="[
        { 'tw-right-0': !alignLeft, 'tw-left-0': alignLeft },
        { 'tw-hidden': !isOpen },
      ]"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        class="tw-w-full tw-min-w-40"
      >
        <component
          :is="item.isRouterLink ? 'router-link' : 'a'"
          :to="item.isRouterLink ? item.to : null"
          class="tw-text-start tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100 tw-cursor-pointer"
          :class="{
            'tw-text-red-700 tw-font-bold tw-text-sm':
              item.isDeleteAction || item.isCriticalAction,
          }"
          @click="handleItemClick(item)"
        >
          <div>
            <div class="tw-font-bold tw-text-sm">{{ item.label }}</div>
            <template v-if="item?.sublabel">
              <div class="tw-text-xs tw-text-gray-800">{{ item.sublabel }}</div>
            </template>
          </div>
        </component>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TheButton from "../../actions/TheButton.vue";

export default {
  name: "ButtonDropdown",
  components: { TheButton },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    buttonText: {
      type: String,
      default: "Add dropdown button title",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hasLeadingIcon: {
      type: Boolean,
      default: false,
    },
    hasTrailingIcon: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "regular",
    },
    variant: {
      type: String,
      default: "tertiary",
    },
    id: {
      type: String,
      required: true,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("elements", {
      openDropdownId: (state) => state.openDropdownId,
    }),
    isOpen() {
      return this.openDropdownId === this.id;
    },
  },
  methods: {
    ...mapActions("elements", ["toggleDropdown", "closeDropdown"]),
    handleToggleDropdown() {
      this.toggleDropdown(this.id);
      if (!this.isOpen) {
        this.setupClickAwayListener();
      } else {
        this.removeClickAwayListener();
      }
    },
    setupClickAwayListener() {
      document.addEventListener("mousedown", this.handleClickAway);
    },
    removeClickAwayListener() {
      document.removeEventListener("mousedown", this.handleClickAway);
    },
    handleClickAway(event) {
      const dropdown = this.$refs.dropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.closeDropdown();
      }
    },
    handleItemClick(item) {
      if (item.action) {
        item.action();
      }
      this.closeDropdown();
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.setupClickAwayListener();
      } else {
        this.removeClickAwayListener();
      }
    },
  },
  beforeUnmount() {
    this.removeClickAwayListener();
  },
};
</script>
