<template>
  <ConfirmDeleteModal
    :media="true"
    @confirm="deleteThisMediaFile()"
    @cancel="closeConfirmDeleteModal()"
    v-if="isConfirmDeleteModalVisible"
  >
    <template #heading>Delete this {{ mediaType }}?</template>
    <template #subheading> </template>
  </ConfirmDeleteModal>

  <template v-if="isLoading">
    <div class="tw-flex tw-bg-white tw-rounded tw-p-4">
      <ListSkeleton :numberOfRows="5"></ListSkeleton>
    </div>
  </template>

  <template v-else-if="!isLoading">
    <TheCard class="tw-grow tw-w-96">
      <CardHeader
        :showTitle="true"
        :showSubheading="false"
        :showActions="true"
        :showBorder="true"
        :title="`${capitalize(mediaType)} Media`"
      >
        <template #subheading-content>
          <p class="tw-m-0 tw-text-sm tw-text-gray-800 tw-w-sm">
            {{ capitalize(mediaType) }} media is utilized as the primary visual
            representation highlighting vendor {{ mediaType }} across different
            collections.
          </p>
        </template>
      </CardHeader>
      <div class="tw-w-full tw-h-full tw-p-2 tw-flex tw-flex-col tw-gap-5">
        <template v-if="!getNewMedia && !getExistingMedia">
          <div
            class="tw-flex tw-items-center tw-justify-center tw-h-full tw-min-h-40"
          >
            <label
              class="tw-w-fit tw-cursor-pointer tw-text-xs tw-py-2 tw-px-2 tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed"
              :for="`${mediaType}Files`"
            >
              Upload media
              <input
                class="form-control"
                type="file"
                :id="`${mediaType}Files`"
                :ref="`${mediaType}Files`"
                accept="image/*"
                multiple
                hidden
                @change="handleMediaUploads($event)"
              />
            </label>
          </div>
        </template>
        <!-- existing media -->
        <template v-if="getExistingMedia">
          <div
            class="tw-border-2 tw-border-t-2 tw-border-black sm:tw-p-4 tw-grow"
          >
            <div
              class="tw-grid tw-grid-cols-2 xs:tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-5 tw-gap-x-2 tw-gap-y-2"
            >
              <div
                v-for="image in existingMedia"
                :key="image._id"
                class="tw-w-full tw-h-0 tw-shadow-lg tw-aspect-w-1 tw-aspect-h-1 tw-my-5"
              >
                <div class="tw-mx-auto tw-w-full">
                  <img
                    :src="image.url"
                    class="tw-h-full tw-w-full tw-object-cover"
                    loading="lazy"
                  />
                  <TheButton
                    :disabled="getNewMedia"
                    variant="ghost"
                    size="large"
                    class="tw-w-full"
                    @click.prevent="showConfirmDeleteModal(image._id)"
                  >
                    <template #text>
                      <b-icon-trash-fill
                        class="tw-text-red-600 tw-mx-auto"
                      ></b-icon-trash-fill>
                    </template>
                  </TheButton>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- new media -->
        <template v-if="getNewMedia">
          <div
            class="tw-border-solid tw-border-t-1 tw-border-black sm:tw-p-4 tw-grow tw-mt-5"
          >
            <div
              class="tw-grid tw-grid-cols-2 xs:tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-5 tw-gap-x-2 tw-gap-y-2"
            >
              <div
                v-for="(file, key) in newMediaFiles"
                :key="'file-' + file.id"
                class="tw-w-full tw-h-0 tw-shadow-lg tw-aspect-w-1 tw-aspect-h-1"
              >
                <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-2">
                  <img
                    :id="'image-' + file.id"
                    class="tw-h-full tw-w-full tw-object-cover"
                    loading="lazy"
                  />
                  <TheButton
                    variant="ghost"
                    size="small"
                    class="tw-w-full"
                    @click.prevent="removeUploadedMediaFile(key)"
                  >
                    <template #text>
                      <span class="tw-text-red-600 tw-mx-auto tw-text-xs"
                        >Remove</span
                      >
                    </template>
                  </TheButton>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="getExistingMedia || getNewMedia">
          <div class="tw-flex tw-gap-2 tw-mt-10 tw-justify-end">
            <template v-if="getNewMedia">
              <template v-if="!mediaIsSaving">
                <TheButton
                  :disabled="mediaIsSaving"
                  variant="tertiary"
                  size="small"
                  @click="cancelUpload()"
                  :hasLeadingIcon="true"
                >
                  <template #leading-icon><b-icon-trash /></template>
                  <template #text> Cancel </template>
                </TheButton>
              </template>

              <TheButton
                :disabled="mediaIsSaving"
                variant="success"
                size="small"
                @click="handleMediaSubmission()"
                :hasLeadingIcon="mediaIsSaving"
              >
                <template #leading-icon>
                  <template v-if="mediaIsSaving">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="tw-sr-only">Loading...</span>
                    </div>
                  </template>
                </template>
                <template #text>
                  <template v-if="mediaIsSaving">Saving...</template>
                  <template v-else>Save</template>
                </template>
              </TheButton>
            </template>
            <template v-if="!mediaIsSaving">
              <label
                class="tw-w-fit tw-cursor-pointer tw-text-xs tw-py-2 tw-px-2 tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed"
                :for="`${mediaType}Files`"
              >
                Add

                <input
                  class="form-control"
                  type="file"
                  :id="`${mediaType}Files`"
                  :ref="`${mediaType}Files`"
                  accept="image/*"
                  multiple
                  hidden
                  @change="handleMediaUploads($event)"
                />
              </label>
            </template>
          </div>
        </template>
      </div>
    </TheCard>
  </template>
</template>

<script>
import ConfirmDeleteModal from "../../components/ui/modals/ConfirmDeleteModal.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import ListSkeleton from "../../components-v2/ui/skeletons/ListSkeleton.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardHeader from "../../components-v2/ui/layout/CardHeader.vue";

export default {
  name: "VendorMediaForm",
  emits: ["handleSuccess"],
  components: {
    CardHeader,
    TheCard,
    ListSkeleton,
    TheButton,
    ConfirmDeleteModal,
  },
  created() {
    // const vendorId = this?.vendor?._id;
    // this.getVendorMedia(vendorId);
    if (this.vendor) {
      this.handleVendorData(this.vendor);
    }
  },
  props: {
    vendor: {
      type: Object,
      // required: false,
    },
    vendorId: {
      type: String,
      required: false,
    },
    vendorList: {
      type: Array,
      required: false,
    },
    mediaType: {
      type: String,
      required: true,
      validator: (value) =>
        ["logos", "gift cards", "vendor branding"].includes(value),
    },
  },
  data() {
    return {
      newMediaFiles: [],
      existingMedia: [],
      isConfirmDeleteModalVisible: false,
      mediaIsSaving: false,
    };
  },
  watch: {
    vendor: {
      handler() {
        this.handleVendorData(this.vendor);
      },
      deep: true, // This ensures that nested changes within the vendor object are also detected
    },
  },
  computed: {
    getNewMedia() {
      return this.newMediaFiles.length > 0 ? this.newMediaFiles : false;
    },
    getExistingMedia() {
      return this.existingMedia.length > 0 ? this.existingMedia : false;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async getVendorMedia(vendorId) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.isLoading = true;

      let filter = { _id: vendorId };
      const queryParameters = {
        limit: 1,
        expand: "media",
      };
      const selection = "media";

      const {
        data: {
          result: { records: singleVendorById },
        },
      } = await api.listVendors(queryParameters, {
        filter: filter,
        selection: selection,
      });

      this.handleVendorData(singleVendorById[0]);
    },
    async handleMediaSubmission() {
      try {
        this.mediaIsSaving = true;
        await this.uploadMedia(this.mediaType, this.newMediaFiles);
        this.newMediaFiles = [];
        this.handleSuccess("uploadMedia");
      } catch (error) {
        console.error(error);
      } finally {
        this.mediaIsSaving = false;
      }
    },
    cancelUpload() {
      this.newMediaFiles = [];
    },
    async uploadMedia(tag, files) {
      try {
        const api = await this.getBoldClient();

        const axiosOpts = {
          headers: { "Content-Type": "multipart/form-data" },
        };

        const uploadPromises = files.map(async (file) => {
          let formData = new FormData();
          formData.append("tags", tag);
          formData.append("vendor", this.vendorId);
          formData.append("media", file.file);
          return await api.uploadMedia(null, formData, axiosOpts);
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        this.handleError(error);
      }
    },
    async deleteThisMediaFile() {
      const api = await this.getBoldClient();
      try {
        await api.deleteMedia(null, {
          filter: { _id: this.idOfMediaToDelete },
        });
        this.handleSuccess("deleteMedia");
      } catch (error) {
        this.handleError(error);
      }
    },
    handleVendorData(vendor) {
      const mediaArr = vendor?.media || [];
      const media = this.filterMediaByTag(mediaArr, this.mediaType);
      this.createMediaUrls(media, this.existingMedia);
      this.isLoading = false;
    },
    filterMediaByTag(mediaArr, tag) {
      return mediaArr.filter((media) => media.metadata.tags.includes(tag));
    },
    async createMediaUrls(mediaArr, mediaUrlArr) {
      const api = await this.getBoldClient();
      mediaUrlArr.length = 0;

      for (const media of mediaArr) {
        const mediaId = media._id;
        const mediaUrl = api.utils.getMediaUrl(mediaId);
        mediaUrlArr.push({ _id: mediaId, url: mediaUrl });
      }
    },
    handleMediaUploads(event) {
      let uploadedFiles = event.target.files;

      for (let i = 0; i < uploadedFiles.length; i++) {
        const uniqueId = Math.random().toString(36).substr(2, 9); // Generate a unique ID
        const fileWithId = {
          id: uniqueId,
          file: uploadedFiles[i],
        };
        this.newMediaFiles.push(fileWithId);
      }
      this.getImagePreviews();
    },
    getImagePreviews() {
      this.newMediaFiles.forEach((file) => {
        if (/\.(jpe?g|png|gif)$/i.test(file.file.name)) {
          let reader = new FileReader();

          reader.onload = () => {
            let imgElement = document.getElementById("image-" + file.id);
            if (imgElement) {
              imgElement.src = reader.result;
            }
          };

          reader.readAsDataURL(file.file);
        }
      });
    },
    removeUploadedMediaFile(key) {
      this.newMediaFiles.splice(key, 1);
    },
    showConfirmDeleteModal(idOfMedia) {
      this.idOfMediaToDelete = idOfMedia;
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    handleSuccess(api) {
      let message = `${this.mediaType} successfully uploaded!`;
      if (api == "deleteMedia") {
        message = `${this.mediaType} successfully deleted!`;
        this.isConfirmDeleteModalVisible = false;
        // this.$emit("mediaDeleted");
      }
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: message,
        duration: 6000,
        id: Date.now(),
      });
      this.getVendorMedia(this.vendorId);
    },
    handleError(error) {
      console.error(`Error updating vendor: ${error}`);
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
