<template>
  <template v-if="showSaveProgressModal">
    <Teleport to="#modal">
      <TheModal :open="showSaveProgressModal">
        <ModalBody>
          <div class="tw-flex tw-gap-2">
            <div role="status">
              <svg
                aria-hidden="true"
                class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="tw-sr-only">Saving...</span>
            </div>
            <p class="tw-text-lg tw-font-bold tw-text-blue-800 tw-m-0">
              Saving your campaign before leaving!
            </p>
          </div>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template
    v-if="sentMessage.link.successful.show || sentMessage.email.successful.show"
  >
    <Teleport to="#modal">
      <TheModal
        :open="
          sentMessage.link.successful.show || sentMessage.email.successful.show
        "
      >
        <ModalBody>
          <SuccessContent
            :campaignSent="
              sentMessage.link.successful.show ||
              sentMessage.email.successful.show
            "
          >
            <template #heading>
              <!-- link -->
              <template v-if="sentMessage.link.successful.show">
                <template v-if="!linkCopied">
                  <div
                    class="d-flex flex-column justify-content-center text-center"
                  >
                    <h2 class="tw-text-6xl tw-font-bold tw-text-gray-900">
                      &#127881;
                    </h2>
                    <h2 class="tw-text-3xl tw-font-bold tw-text-blue-800">
                      Your gift card link is ready to share!
                    </h2>
                  </div>
                </template>
              </template>
              <!-- email -->
              <template v-if="sentMessage.email.successful.show">
                <div
                  class="d-flex flex-column justify-content-center text-center"
                >
                  <div>
                    <img
                      style="max-height: 150px; width: auto"
                      src="../../assets/svg/illustrations-test/16_email_campaign_selection.png"
                      alt="Image Description"
                    />
                  </div>
                  <h2 class="tw-text-3xl tw-font-bold tw-text-blue-800">
                    Your email campaign is on it's way!
                  </h2>
                </div>
              </template>
            </template>
            <template #content-slot-1>
              <template v-if="sentMessage.link.successful.show">
                <template v-if="linkCopied">
                  <div
                    class="card-body d-flex gap-2 flex-column align-items-center justify-content-center"
                  >
                    <div
                      class="tw-flex tw-flex-col tw-gap-5 tw-justify-center tw-items-center"
                    >
                      <b-icon-check-square-fill
                        class="tw-text-6xl tw-font-bold tw-text-green-500"
                      ></b-icon-check-square-fill>
                      <div>
                        <h2 class="tw-text-3xl tw-font-bold tw-text-blue-800">
                          Gift card collection link copied!
                        </h2>
                        <p class="tw-text-lg tw-m-0 tw-max-w-96">
                          Paste the gift link into the email you send to your
                          prospect or client.
                        </p>
                      </div>
                      <div class="tw-flex tw-gap-4">
                        <TheButton
                          :isRouterLink="true"
                          variant="primary"
                          size="large"
                          :to="{
                            name: 'CampaignDetails',
                            params: { campaignId: this.campaign._id },
                          }"
                        >
                          <template #text>View campaign report</template>
                        </TheButton>

                        <TheButton
                          :isRouterLink="true"
                          variant="tertiary"
                          size="large"
                          to="/campaigns"
                        >
                          <template #text>Close</template>
                        </TheButton>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="sentMessage.email.successful.show">
                <div class="tw-flex tw-justify-center">
                  <TheButton
                    :isRouterLink="true"
                    variant="tertiary"
                    size="large"
                    :to="{
                      name: 'CampaignDetails',
                      params: { campaignId: this.campaign._id },
                    }"
                  >
                    <template #text>Close</template>
                  </TheButton>
                </div>
              </template>
            </template>

            <template #content-slot-2>
              <template v-if="sentMessage.link.successful.show">
                <template v-if="!linkCopied">
                  <div
                    class="tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center"
                  >
                    <div class="tw-mx-auto">
                      <TheButton
                        variant="secondary"
                        size="large"
                        @click.stop="copyLink()"
                      >
                        <template #text>Copy link</template>
                      </TheButton>
                    </div>
                    <BlockStack :gap="100" class="tw-w-lg">
                      <p class="tw-text-lg tw-m-0">
                        Copy this unique gift link—add it to the email you send
                        your prospect or client.
                      </p>
                      <p class="tw-text-lg tw-m-0">
                        You can access this link at a future date
                        <router-link
                          :to="{
                            name: 'CampaignDetails',
                            params: { campaignId: this.campaign._id },
                          }"
                          >here</router-link
                        >.
                      </p>
                    </BlockStack>
                  </div>
                </template>
              </template>
            </template>
          </SuccessContent>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <PageHeader
    :title="isTemplateBuilder ? 'Template Builder' : 'Campaign Builder'"
  >
  </PageHeader>

  <template v-if="!campaignIsSaving && !campaignsAreLoading">
    <nav aria-label="Progress">
      <ol
        role="list"
        class="tw-list-none tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-gray-300 tw-border-solid tw-bg-blue-50 tw-border tw-border-x-0 tw-border-gray-300 md:tw-flex md:tw-divide-y-0 tw-p-0 tw-m-0"
      >
        <template v-if="!isTemplateBuilder">
          <li
            v-for="(step, stepIdx) in steps"
            :key="step.name"
            class="tw-relative md:tw-flex md:tw-flex-1 tw-bg-white"
          >
            <!-- step IS complete && NOT current && NOT step 5 -->
            <a
              v-if="
                step.id !== this.currentStep && step.status && step.id !== 4
              "
              @click.prevent="handleStepChange(step.id)"
              class="tw-group tw-flex tw-w-full tw-items-center tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-w-5 tw-h-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-50"
                >
                  <span class="tw-text-green-600 tw-text-xl"
                    ><b-icon-check></b-icon-check
                  ></span>
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900"
                  >{{ step.name }}</span
                >
              </span>
            </a>
            <!-- step IS complete && IS current && NOT step 5 -->
            <a
              v-else-if="
                step.id === this.currentStep && step.status && step.id !== 4
              "
              @click.prevent="handleStepChange(step.id)"
              class="tw-group tw-flex tw-w-full tw-items-center tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-2 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-w-5 tw-h-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100 tw-border-3 tw-border-solid tw-border-green-600"
                >
                  <span class="tw-text-green-600 tw-text-xl"
                    ><b-icon-check></b-icon-check
                  ></span>
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-font-bold tw-text-green-600"
                  >{{ step.name }}</span
                >
              </span>
            </a>
            <!-- step IS NOT complete && NOT current && NOT step 5 -->
            <a
              v-else-if="
                step.id !== this.currentStep && !step.status && step.id !== 4
              "
              @click="handleStepChange(step.id)"
              class="tw-group tw-flex tw-w-full tw-items-center tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-w-5 tw-h-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-50"
                >
                  <span class="tw-text-red-600 tw-text-lg"
                    ><b-icon-exclamation></b-icon-exclamation
                  ></span>
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900"
                  >{{ step.name }}</span
                >
              </span>
            </a>
            <!-- step IS NOT complete && IS current && NOT step 5 -->
            <a
              v-else-if="
                step.id === this.currentStep && !step.status && step.id !== 4
              "
              @click="handleStepChange(step.id)"
              class="tw-group tw-flex tw-w-full tw-items-center tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-w-5 tw-h-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-solid tw-bg-red-100 tw-border-3 tw-red-solid tw-border-red-600"
                >
                  <span class="tw-text-red-600 tw-text-lg"
                    ><b-icon-exclamation></b-icon-exclamation
                  ></span>
                </span>
                <span class="tw-ml-4 tw-text-sm tw-font-bold tw-text-red-600">{{
                  step.name
                }}</span>
              </span>
            </a>

            <!-- IS NOT current && IS step 5 -->
            <button
              v-if="step.id !== this.currentStep && step.id === 4"
              @click="handleStepChange(step.id)"
              class="tw-border-0 tw-bg-transparent tw-group tw-flex tw-items-center disabled:tw-cursor-not-allowed tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-h-5 tw-w-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 group-hover:tw-border-gray-900"
                  :class="{
                    'tw-text-red-500 tw-bg-gray-100 ':
                      !allStepsComplete || !campaignIsComplete,
                    'tw-text-gray-500 group-hover:tw-text-gray-900 tw-bg-blue-100':
                      allStepsComplete,
                  }"
                >
                  <span class="tw-text-red-600 tw-text-lg"
                    ><b-icon-exclamation></b-icon-exclamation
                  ></span>
                  <!-- <span
                    :class="{
                      'tw-text-red-500 tw-font-medium':
                        !allStepsComplete || !campaignIsComplete,
                      'tw-text-gray-900 tw-font-bold': allStepsComplete,
                    }"
                    >{{ step.id }}</span
                  > -->
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-text-start"
                  :class="{
                    'tw-text-red-500 tw-font-medium':
                      !allStepsComplete || !campaignIsComplete,
                    'tw-text-gray-900 tw-font-bold': allStepsComplete,
                  }"
                >
                  {{
                    allStepsComplete && campaignIsComplete
                      ? step.name
                      : "Review"
                  }}
                </span>
              </span>
            </button>
            <!-- IS current && IS step 5 -->
            <a
              v-if="step.id === this.currentStep && step.id === 4"
              class="tw-border-0 tw-bg-transparent tw-group tw-flex tw-items-center"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-h-5 tw-w-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 group-hover:tw-border-gray-900"
                  :class="{
                    'tw-text-red-500 tw-bg-gray-100 ': !allStepsComplete,
                    'tw-text-gray-500 group-hover:tw-text-gray-900 tw-bg-blue-100':
                      allStepsComplete,
                  }"
                >
                  <span
                    class="tw-text-lg"
                    :class="{
                      'tw-text-red-600 ': !allStepsComplete,
                      'tw-text-gray-900 ': allStepsComplete,
                    }"
                    ><b-icon-exclamation></b-icon-exclamation
                  ></span>
                  <!-- <span
                    :class="{
                      'tw-text-red-500 tw-font-medium': !allStepsComplete,
                      'tw-text-gray-900 tw-font-bold': allStepsComplete,
                    }"
                    >{{ step.id }}</span
                  > -->
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-text-start"
                  :class="{
                    'tw-text-red-500 tw-font-medium': !allStepsComplete,
                    'tw-text-gray-900 tw-font-bold': allStepsComplete,
                  }"
                >
                  {{
                    allStepsComplete
                      ? step.name
                      : "Complete all steps to review"
                  }}
                </span>
              </span>
            </a>

            <template v-if="stepIdx !== steps.length - 1">
              <div
                class="tw-absolute tw-right-0 tw-top-0 tw-hidden tw-h-full tw-w-5 md:tw-block"
                aria-hidden="true"
              >
                <svg
                  class="tw-h-full tw-w-full tw-text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vector-effect="non-scaling-stroke"
                    stroke="currentcolor"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
          </li>
        </template>
        <template v-if="isTemplateBuilder">
          <li
            v-for="(step, stepIdx) in templateSteps"
            :key="step.name"
            class="tw-relative md:tw-flex md:tw-flex-1 tw-bg-white"
          >
            <!-- step IS complete && NOT current && NOT step 5 -->
            <a
              v-if="
                step.id !== this.currentStep && step.status && step.id !== 4
              "
              @click.prevent="handleStepChange(step.id)"
              class="tw-group tw-flex tw-w-full tw-items-center tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-w-5 tw-h-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-50"
                >
                  <span class="tw-text-green-600 tw-text-xl"
                    ><b-icon-check></b-icon-check
                  ></span>
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900"
                  >{{ step.name }}</span
                >
              </span>
            </a>
            <!-- step IS complete && IS current && NOT step 5 -->
            <a
              v-else-if="
                step.id === this.currentStep && step.status && step.id !== 4
              "
              @click.prevent="handleStepChange(step.id)"
              class="tw-group tw-flex tw-w-full tw-items-center tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-2 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-w-5 tw-h-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100 tw-border-3 tw-border-solid tw-border-green-600"
                >
                  <span class="tw-text-green-600 tw-text-xl"
                    ><b-icon-check></b-icon-check
                  ></span>
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-font-bold tw-text-green-600"
                  >{{ step.name }}</span
                >
              </span>
            </a>
            <!-- step IS NOT complete && NOT current && NOT step 5 -->
            <a
              v-else-if="
                step.id !== this.currentStep && !step.status && step.id !== 4
              "
              @click="handleStepChange(step.id)"
              class="tw-group tw-flex tw-w-full tw-items-center tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-w-5 tw-h-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-50"
                >
                  <span class="tw-text-red-600 tw-text-lg"
                    ><b-icon-exclamation></b-icon-exclamation
                  ></span>
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900"
                  >{{ step.name }}</span
                >
              </span>
            </a>
            <!-- step IS NOT complete && IS current && NOT step 5 -->
            <a
              v-else-if="
                step.id === this.currentStep && !step.status && step.id !== 4
              "
              @click="handleStepChange(step.id)"
              class="tw-group tw-flex tw-w-full tw-items-center tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-w-5 tw-h-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-solid tw-bg-red-100 tw-border-3 tw-red-solid tw-border-red-600"
                >
                  <span class="tw-text-red-600 tw-text-lg"
                    ><b-icon-exclamation></b-icon-exclamation
                  ></span>
                </span>
                <span class="tw-ml-4 tw-text-sm tw-font-bold tw-text-red-600">{{
                  step.name
                }}</span>
              </span>
            </a>

            <!-- IS NOT current && IS step 5 -->
            <button
              v-if="step.id !== this.currentStep && step.id === 4"
              @click="handleStepChange(step.id)"
              class="tw-border-0 tw-bg-transparent tw-group tw-flex tw-items-center disabled:tw-cursor-not-allowed tw-cursor-pointer"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-h-5 tw-w-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 group-hover:tw-border-gray-900"
                  :class="{
                    'tw-text-red-500 tw-bg-gray-100 ':
                      !allStepsComplete || !campaignIsComplete,
                    'tw-text-gray-500 group-hover:tw-text-gray-900 tw-bg-blue-100':
                      allStepsComplete,
                  }"
                >
                  <span class="tw-text-red-600 tw-text-lg"
                    ><b-icon-exclamation></b-icon-exclamation
                  ></span>
                  <!-- <span
                    :class="{
                      'tw-text-red-500 tw-font-medium':
                        !allStepsComplete || !campaignIsComplete,
                      'tw-text-gray-900 tw-font-bold': allStepsComplete,
                    }"
                    >{{ step.id }}</span
                  > -->
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-text-start"
                  :class="{
                    'tw-text-red-500 tw-font-medium':
                      !allStepsComplete || !campaignIsComplete,
                    'tw-text-gray-900 tw-font-bold': allStepsComplete,
                  }"
                >
                  {{
                    allStepsComplete && campaignIsComplete
                      ? step.name
                      : "Review"
                  }}
                </span>
              </span>
            </button>
            <!-- IS current && IS step 5 -->
            <a
              v-if="step.id === this.currentStep && step.id === 4"
              class="tw-border-0 tw-bg-transparent tw-group tw-flex tw-items-center"
            >
              <span
                class="tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-sm tw-font-medium"
              >
                <span
                  class="tw-flex tw-h-5 tw-w-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 group-hover:tw-border-gray-900"
                  :class="{
                    'tw-text-red-500 tw-bg-gray-100 ': !allStepsComplete,
                    'tw-text-gray-500 group-hover:tw-text-gray-900 tw-bg-blue-100':
                      allStepsComplete,
                  }"
                >
                  <span class="tw-text-red-600 tw-text-lg"
                    ><b-icon-exclamation></b-icon-exclamation
                  ></span>
                  <!-- <span
                    :class="{
                      'tw-text-red-500 tw-font-medium': !allStepsComplete,
                      'tw-text-gray-900 tw-font-bold': allStepsComplete,
                    }"
                    >{{ step.id }}</span
                  > -->
                </span>
                <span
                  class="tw-ml-4 tw-text-sm tw-text-start"
                  :class="{
                    'tw-text-red-500 tw-font-medium': !allStepsComplete,
                    'tw-text-gray-900 tw-font-bold': allStepsComplete,
                  }"
                >
                  {{
                    allStepsComplete
                      ? step.name
                      : "Complete all steps to review"
                  }}
                </span>
              </span>
            </a>

            <template v-if="stepIdx !== steps.length - 1">
              <div
                class="tw-absolute tw-right-0 tw-top-0 tw-hidden tw-h-full tw-w-5 md:tw-block"
                aria-hidden="true"
              >
                <svg
                  class="tw-h-full tw-w-full tw-text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vector-effect="non-scaling-stroke"
                    stroke="currentcolor"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
          </li>
        </template>
      </ol>
    </nav>
  </template>
  <template v-if="campaignsAreLoading || campaignIsSaving">
    <ThePage customWidthClass="tw-w-full tw-max-w-5xl tw-mx-auto">
      <div
        class="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full tw-max-h-2xl tw-max-w-2xl tw-m-auto"
      >
        <PageSection :fullWidth="true">
          <PulseAnimation>
            <ListSkeleton :numberOfRows="5"></ListSkeleton>
          </PulseAnimation>
        </PageSection>
      </div>
    </ThePage>
  </template>

  <template v-if="!isTemplateBuilder">
    <template
      v-if="currentStep === 1 && !campaignsAreLoading && !campaignIsSaving"
    >
      <TheRecipients
        :campaign="campaign"
        :areCampaignRecipientsVisible="areCampaignRecipientsVisible"
        @loadSteps="loadSteps"
      ></TheRecipients>
    </template>
  </template>
  <template
    v-if="currentStep === 2 && !campaignsAreLoading && !campaignIsSaving"
  >
    <TheCampaignSettings
      :campaign="campaign"
      :budgetSessionId="budgetSessionId"
      :customSelection="customSelection"
      :isCustomGiftCardSelection="isCustomGiftCardSelection"
      @resetSessionId="resetSessionId"
      @setNewBudget="setNewBudget"
      @loadSteps="loadSteps"
      @handlePaymentMethodSetup="handlePaymentMethodSetup"
    ></TheCampaignSettings>
  </template>
  <template
    v-if="currentStep === 3 && !campaignsAreLoading && !campaignIsSaving"
  >
    <GiftCardsGridV2
      @updateCampaignCustomSelection="updateCampaignCustomSelection"
      @handleStepChange="handleStepChange"
      :campaign="campaign"
      :custom_selection_ids="customSelection"
      :isCampaignBuilder="true"
    >
    </GiftCardsGridV2>
  </template>

  <template
    v-if="currentStep === 4 && !campaignsAreLoading && !campaignIsSaving"
  >
    <template v-if="!paymentInformation">
      <div
        class="tw-border-red-400 tw-bg-red-50 tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-items-center tw-gap-4 tw-w-full tw-mx-auto tw-mb-3"
      >
        <b-icon-exclamation-circle
          class="tw-text-red-700"
        ></b-icon-exclamation-circle>
        <p class="tw-text-sm tw-m-0 tw-text-start tw-text-red-700">
          We couldn't find a credit card on your profile. Please add a credit
          card
          <RouterLink to="/account" class="tw-font-bold">here</RouterLink>
          to able to send campaigns.
        </p>
      </div>
    </template>
    <ReviewDetails
      @handleStepChange="handleStepChange"
      :customSelection="customSelection"
      :isCustomGiftCardSelection="isCustomGiftCardSelection"
      :isTemplateBuilder="isTemplateBuilder"
      :campaign="campaign"
    ></ReviewDetails>
  </template>

  <div class="tw-pt-40">
    <PageHeader
      :showActions="true"
      class="tw-fixed tw-bottom-0 tw-inset-x-auto tw-right-0 tw-w-full tw-bg-white/75"
    >
      <template #page-header-actions>
        <template
          v-if="allStepsComplete && campaignIsComplete && currentStep !== 4"
        >
          <div class="tw-flex tw-gap-2">
            <template v-if="!campaignIsSaving || !campaignsAreLoading">
              <TheButton
                :disabled="campaignIsSaving || campaignsAreLoading"
                :hasLeadingIcon="campaignIsSaving || campaignsAreLoading"
                variant="tertiary"
                @click="saveAndExit()"
                size="large"
              >
                <template #leading-icon>
                  <template v-if="campaignIsSaving || campaignsAreLoading">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="tw-sr-only">Saving...</span>
                    </div>
                  </template>
                  <template v-else><b-icon-plus-lg></b-icon-plus-lg></template>
                </template>
                <template #text>
                  <template v-if="campaignIsSaving || campaignsAreLoading"
                    >Saving</template
                  >
                  <template v-else>Finish later</template>
                </template>
              </TheButton>
            </template>
            <TheButton variant="new" @click="handleStepChange(4)" size="large">
              <template #text> Review and finalize</template>
            </TheButton>
          </div>
        </template>
        <template
          v-if="(!allStepsComplete || !campaignIsComplete) && currentStep !== 4"
        >
          <div class="tw-flex tw-gap-2">
            <template v-if="!campaignIsSaving || !campaignsAreLoading">
              <TheButton
                :disabled="campaignIsSaving || campaignsAreLoading"
                :hasLeadingIcon="campaignIsSaving || campaignsAreLoading"
                variant="tertiary"
                @click="saveAndExit()"
                size="large"
              >
                <template #leading-icon>
                  <template v-if="campaignIsSaving || campaignsAreLoading">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="tw-sr-only">Saving...</span>
                    </div>
                  </template>
                  <template v-else><b-icon-plus-lg></b-icon-plus-lg></template>
                </template>
                <template #text>
                  <template v-if="campaignIsSaving || campaignsAreLoading"
                    >Saving</template
                  >
                  <template v-else>Finish later</template>
                </template>
              </TheButton>
            </template>

            <TheButton
              :disabled="
                (currentStep === 4 && !allStepsComplete) ||
                campaignIsSaving ||
                campaignsAreLoading
              "
              :hasTrailingIcon="!campaignIsSaving && !campaignsAreLoading"
              :hasLeadingIcon="campaignIsSaving || campaignsAreLoading"
              variant="secondary"
              @click="goToNextStep()"
              size="large"
            >
              <template #leading-icon>
                <template v-if="campaignIsSaving || campaignsAreLoading">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="tw-sr-only">Saving...</span>
                  </div>
                </template>
                <template v-else><b-icon-plus-lg></b-icon-plus-lg></template>
              </template>
              <template #text>
                <template v-if="campaignIsSaving || campaignsAreLoading"
                  >Saving</template
                >
                <template v-else>Save and continue</template>
              </template>
              <template #trailing-icon>
                <template v-if="currentStep !== 4">
                  <b-icon-arrow-right></b-icon-arrow-right>
                </template>
              </template>
            </TheButton>
          </div>
        </template>
        <template v-if="currentStep === 4">
          <div class="tw-flex tw-gap-2">
            <template v-if="!campaignIsSaving || !campaignsAreLoading">
              <TheButton
                :disabled="campaignIsSaving || campaignsAreLoading"
                :hasLeadingIcon="campaignIsSaving || campaignsAreLoading"
                variant="tertiary"
                @click="saveAndExit()"
                size="large"
              >
                <template #leading-icon>
                  <template v-if="campaignIsSaving || campaignsAreLoading">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="tw-sr-only">Saving...</span>
                    </div>
                  </template>
                  <template v-else><b-icon-plus-lg></b-icon-plus-lg></template>
                </template>
                <template #text>
                  <template v-if="campaignIsSaving || campaignsAreLoading"
                    >Saving</template
                  >
                  <template v-else>Finish later</template>
                </template>
              </TheButton>
            </template>
            <TheButton
              :disabled="
                !allStepsComplete || !campaignIsComplete || !paymentInformation
              "
              :class="{
                'tw-animate-pulse':
                  this.currentStep &&
                  allStepsComplete &&
                  campaignIsComplete &&
                  paymentInformation,
              }"
              :hasTrailingIcon="!campaignIsSaving && !campaignsAreLoading"
              :hasLeadingIcon="campaignIsSaving || campaignsAreLoading"
              variant="success"
              @click="handleSendingCampaign()"
              size="large"
            >
              <template v-if="campaignIsSaving || campaignsAreLoading">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="tw-sr-only">Sending...</span>
                </div>
              </template>
              <template #text>
                <template v-if="campaignIsSaving || campaignsAreLoading"
                  >Sending campaign</template
                >
                <template v-else>
                  <template v-if="this.isTemplateBuilder">
                    Finalize template
                  </template>
                  <template v-else> Send campaign </template>
                </template>
              </template>

              <template #trailing-icon>
                <template v-if="currentStep !== 4">
                  <b-icon-arrow-right></b-icon-arrow-right>
                </template>
              </template>
            </TheButton>
          </div>
        </template>
      </template>
    </PageHeader>
  </div>
</template>

<script>
import TheRecipients from "../../components-v2/campaigns/builder/TheRecipients";
import TheCampaignSettings from "../../components-v2/campaigns/builder/TheCampaignSettings";
import ReviewDetails from "../../components-v2/campaigns/builder/ReviewDetails";
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheButton from "@/components-v2/ui/actions/TheButton.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import SuccessContent from "../../components-v2/ui/modals/SuccessContent.vue";
import GiftCardsGridV2 from "../../components-v2/gift-cards/GiftCardsGridV2.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import ListSkeleton from "../../components-v2/ui/skeletons/ListSkeleton.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import userMixin from "../../mixins/userMixin";
import { getDefaultPaymentInformation } from "@/services/stripeService";

import {
  upsertMailCampaign,
  sendMailCampaign,
} from "@/services/campaignService";

export default {
  name: "CampaignBuilderV2",
  components: {
    ReviewDetails,
    TheCampaignSettings,
    TheRecipients,
    BlockStack,
    GiftCardsGridV2,
    ThePage,
    PageHeader,
    TheButton,
    TheModal,
    ModalBody,
    SuccessContent,
    PageSection,
    ListSkeleton,
    PulseAnimation,
    NotificationMessage,
  },
  mixins: [userMixin],
  async created() {
    await this.handleCreatedLifecycleLogic();
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.showSaveProgressModal && !this.campaignIsSaving) {
      next();
    } else {
      await this.updateCampaign();
      this.$store.commit(
        "campaigns/RESET_CAMPAIGN_GIFTS_SELECTION_REQUEST_BODY",
        {}
      );
      next();
    }
  },

  props: {
    isTemplateBuilder: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    currentStep: {
      handler: "loadSteps",
      deep: true,
    },
  },
  data() {
    return {
      campaign: {},
      defaultPaymentInformation: {},
      sentMessage: {
        email: {
          successful: {
            show: false,
          },
          failed: {
            text: "We experienced some issues while attempting to send your mail campaign. Please try again.",
            class: "alert-soft-danger alert-dismissible fade show",
            show: false,
          },
        },
        link: {
          successful: {
            show: false,
          },
          failed: {
            text: "We experienced some issues while attempting to send your shareable link campaign. Please try again.",
            class: "alert-soft-danger alert-dismissible fade show",
            show: false,
          },
        },
      },
      calendarState: {
        google: false,
        office365: false,
      },
      booking: null,
      steps: [
        { id: 1, name: "Pick recipients", status: 0 },
        { id: 2, name: "Set gift parameters", status: 0 },
        { id: 3, name: "Select gift cards", status: 0 },
        { id: 4, name: "Review details", status: 0 },
      ],
      templateSteps: [
        // { id: 1, name: "Pick recipients", status: 0 },
        { id: 2, name: "Set gift parameters", status: 0 },
        { id: 3, name: "Select gift cards", status: 0 },
        { id: 4, name: "Review details", status: 0 },
      ],
      newCampaignName: "",
      currentStep: 1,
      showSaveProgressModal: false,
      isConfirmedLeave: false,
      budgetSessionId: null,
      linkCopied: false,
      showSuccessModal: true,
      giftCardAmountIsValid: false,
      customGiftCardValueSelected: false,
      isToggled: false,
      campaignsAreLoading: true,
      campaignIsSaving: false,
      campaignIsComplete: false,
      showGlobalError: false,
      areCampaignRecipientsVisible: false,
      nameEditIsActive: false,
    };
  },
  computed: {
    // check if all campaign requirements are complete
    allStepsComplete() {
      if (this.isTemplateBuilder) {
        return (
          this.getCampaignGiftCardAmount > 0 &&
          ((this.getCampaignType === "email" &&
            this.getCampaignEmailSubject &&
            this.getCampaignEmailText) ||
            this.getCampaignType === "link")
        );
      } else {
        return (
          this.getCampaignRecipientsIds.length > 0 &&
          this.getCampaignGiftCardAmount > 0 &&
          ((this.getCampaignType === "email" &&
            this.getCampaignEmailSubject &&
            this.getCampaignEmailText) ||
            this.getCampaignType === "link")
        );
      }
    },
    // User
    User() {
      return this?.$store?.state?.auth?.user;
    },
    // Campaign from details
    campaignFromEmail() {
      return this?.campaign?.from?.email;
    },
    campaignFromName() {
      return this?.campaign?.from?.name;
    },
    // tooltip content

    giftCardSelectionTooltipContent() {
      return this.isCustomGiftCardSelection
        ? "Recipients first see your curated list of e-gift cards, but they can always explore and choose any card from the Bold collection."
        : "Recipients get to explore and pick any e-gift card from the Bold collection.";
    },
    giftCardAmountTooltipContent() {
      return "The cash value of the e-gift card that recipients can redeem.";
    },
    sendModeTooltipContent() {
      return this.getCampaignType === "link"
        ? "You'll receive a unique link to share your campaign with your recipients exactly how you want."
        : "We'll send your campaign through our email builder, complete with an e-gift card link for easy recipient engagement.";
    },
    campaignRecipients() {
      return (
        this?.campaign?.recipients?.map((recipient) => recipient._id) || []
      );
    },
    campaignSubject() {
      return this?.campaign?.subject;
    },
    campaignGiftUrl() {
      return `${window.location.origin}/gift/${this?.campaign?._id}`;
    },
    campaignName() {
      return this?.campaign?.metadata?.campaign_name
        ? this?.campaign?.metadata?.campaign_name
        : "Untitled Campaign";
    },
    // gift card selection
    isCustomGiftCardSelection() {
      return this?.campaign?.metadata?.collection_query?.custom_selection_ids &&
        this?.campaign?.metadata?.collection_query?.custom_selection_ids !== 1
        ? true
        : false;
    },
    // Vendor ids of custom gift card selection
    customSelection() {
      return this?.campaign?.metadata?.collection_query
        ?.custom_selection_ids === 0 ||
        this?.campaign?.metadata?.collection_query?.custom_selection_ids
          .length === 0
        ? 0
        : this?.campaign?.metadata?.collection_query?.custom_selection_ids;
    },

    paymentInformation() {
      return !!this?.defaultPaymentInformation?.card;
    },

    /** error notifications */
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    getCampaignSelectionRequestBody() {
      return this.$store.getters["campaigns/getCampaignSelectionRequestBody"];
    },

    /** getters */
    getCampaignTemplateStatus() {
      return this.$store.getters["campaigns/getCampaignTemplateStatus"];
    },
    getCampaignName() {
      return this.$store.getters["campaigns/getCampaignName"];
    },
    getCampaignGiftingLeadName() {
      return this.$store.getters["campaigns/getCampaignGiftingLeadName"];
    },
    getCampaignGiftingLeadEmail() {
      return this.$store.getters["campaigns/getCampaignGiftingLeadEmail"];
    },
    getCampaignRecipients() {
      return this.$store.getters["campaigns/getCampaignRecipients"];
    },
    getCampaignRecipientsIds() {
      return this.$store.getters["campaigns/getCampaignRecipientsIds"];
    },
    getCampaignType() {
      return this.$store.getters["campaigns/getCampaignType"];
    },
    getCampaignEmailSubject() {
      return this.$store.getters["campaigns/getCampaignEmailSubject"];
    },
    getCampaignEmailText() {
      return this.$store.getters["campaigns/getCampaignEmailText"];
    },
    getCampaignEmailHtmlDisplay() {
      return this.$store.getters["campaigns/getCampaignEmailHtmlDisplay"];
    },
    getCampaignEmailHtmlData() {
      return this.$store.getters["campaigns/getCampaignEmailHtmlData"];
    },
    getCampaignGiftCardAmount() {
      return this.$store.getters["campaigns/getCampaignGiftCardAmount"];
    },
    getCampaignBudgetId() {
      return this.$store.getters["campaigns/getCampaignBudgetId"];
    },
    getCampaignSelectedBudgetLimitIsMet() {
      return this.$store.getters[
        "campaigns/getCampaignSelectedBudgetLimitIsMet"
      ];
    },
    getCampaignBudgetTracker() {
      return this.$store.getters["campaigns/getCampaignBudgetTracker"];
    },
    getCampaignBookingData() {
      return this.$store.getters["campaigns/getCampaignBookingData"];
    },
    getCampaignBookingProvider() {
      return this.$store.getters["campaigns/getCampaignBookingProvider"];
    },
    getCampaignBookingSummary() {
      return this.$store.getters["campaigns/getCampaignBookingSummary"];
    },
    getCampaignBookingDescription() {
      return this.$store.getters["campaigns/getCampaignBookingDescription"];
    },
    getCampaignBookingRequirement(newVal, oldVal) {
      // console.log("Booking requirement changed from", oldVal, "to", newVal);
      return this.$store.getters["campaigns/getCampaignBookingRequirement"];
    },
    getCampaignBookingEnabled() {
      return this.$store.getters["campaigns/getCampaignBookingEnabled"];
    },
    /** gift note */
    getCampaignLandingPageData() {
      return this.$store.getters["campaigns/getCampaignLandingPageData"];
    },
    getCampaignLandingPageEnabledStatus() {
      return this.$store.getters[
        "campaigns/getCampaignLandingPageEnabledStatus"
      ];
    },
    getCampaignLandingPageRequiredStatus() {
      return this.$store.getters[
        "campaigns/getCampaignLandingPageRequiredStatus"
      ];
    },
    getCampaignLandingPageGiftNote() {
      return this.$store.getters["campaigns/getCampaignLandingPageGiftNote"];
    },
    getCampaignLandingPageTitle() {
      return this.$store.getters["campaigns/getCampaignLandingPageTitle"];
    },
    getCampaignLandingPageMessage() {
      return this.$store.getters["campaigns/getCampaignLandingPageMessage"];
    },
  },
  methods: {
    /** handle loading campaign by id */
    async loadCampaignDetails() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      this.campaignsAreLoading = true;
      const campaignId = this.$route.params.campaignId;

      try {
        const queryParameters = {
          limit: 1,
          expand: "recipients contact",
        };
        const requestBody = {
          filter: { _id: campaignId },
        };
        const response = await api.listMailCampaigns(
          queryParameters,
          requestBody
        );
        const campaign = response.data.result.records[0];
        this.campaign = campaign;
        const queryRequestBody =
          campaign?.metadata?.collection_query?.requestBody || {};

        
        this.$store.commit("campaigns/SET_CAMPAIGN_BUILDER_DATA", campaign);
        this.$store.commit(
          "campaigns/SET_CAMPAIGN_GIFTS_SELECTION_REQUEST_BODY",
          queryRequestBody
        );
        if (this.getCampaignRecipientsIds.length > 0) {
          this.$store.commit(
            "campaigns/SET_CAMPAIGN_BUILDER_RECIPIENTS_VISIBLE",
            true
          );
        }
        if (campaign?.booking?.provider) {
          if (campaign.booking.provider === "google") {
            this.calendarState.google = true;
            this.calendarState.office365 = false;
          } else {
            this.calendarState.google = false;
            this.calendarState.office365 = true;
          }
        }
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your campaign DETAILS. Please reload the page and try again."
        );
      } finally {
        this.campaignsAreLoading = false;
      }
    },

    /** handle sending campaign */
    async handleSendingCampaign() {
      this.campaignIsSaving = true;

      const campaignPatch = this.getFinalCampaignPatch();
      const campaignId = this.campaign._id;

      try {
        // Attempt to upsert the campaign
        await upsertMailCampaign(campaignId, campaignPatch);

        // If the campaign type is "email", proceed to send it
        if (this.isTemplateBuilder) {
          this.$router.push("/templates");
        } else {
          if (this.getCampaignType === "email") {
            try {
              await sendMailCampaign(this.campaign._id);
              // If sending succeeds, notify the user accordingly
              this.handleSuccess("email");
            } catch (error) {
              // Handle errors specifically from the send operation
              this.handleErrorFromAPI(
                error,
                "We encountered an issue while sending your email campaign. Please reload the page and try sending it again."
              );
              return; // Return to prevent executing link success message
            }
          } else {
            this.handleSuccess("link");
          }
        }
      } catch (error) {
        // Handle errors from the upsert operation
        this.handleErrorFromAPI(
          error,
          "We encountered an issue while sending your link campaign. Please reload the page and try sending it again."
        );
      } finally {
        this.campaignIsSaving = false;
        this.nameEditIsActive = false;
      }
    },
    getFinalCampaignPatch() {
      const campaignBudgetID = this.getCampaignBudgetId || null;
      // console.log("campaignBudgetID: ", campaignBudgetID);

      const finalCampaign = {
        from: this.getCampaignFromData(),
        recipients: this.getCampaignRecipientsIds,
        subject: this.getCampaignEmailSubject,
        text: this.getCampaignEmailText,
        html: this.getCampaignEmailHtmlData,
        ...this.getFinalCampaignMetadata(campaignBudgetID),
        user: this?.User?.id,
      };

      // Call the function to add booking data to the patch object
      this.addBookingDataToPatch(finalCampaign);

      return finalCampaign;
    },

    /** handle upserting a campaign by id */
    async updateCampaign() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      this.campaignIsSaving = true;
      const campaignPatch = this.getCampaignUpdatePatch();
      const campaignId = this.campaign._id;

      try {
        await api.upsertMailCampaign(null, {
          filter: { _id: campaignId },
          patch: campaignPatch,
        });

        const requestBody = this.campaign.metadata.collection_query.requestBody;

        this.$store.commit(
          "campaigns/SET_CAMPAIGN_GIFTS_SELECTION_REQUEST_BODY",
          requestBody
        );

        this.handleSuccess();
        return true;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to save your campaign. Please try again."
        );
      } finally {
        this.campaignIsSaving = false;
      }
    },
    getCampaignUpdatePatch() {
      const campaignUpdatePatch = {
        from: this.getCampaignFromData(),
        recipients: this.getCampaignRecipientsIds,
        subject: this.getCampaignEmailSubject,
        text: this.getCampaignEmailText,
        html: this.getCampaignEmailHtmlData,
        ...this.getCampaignMetadata(),
        user: this?.User?.id,
      };

      // Call the function to add booking data to the patch object
      this.addBookingDataToPatch(campaignUpdatePatch);

      return campaignUpdatePatch;
    },
    getCampaignFromData() {
      return {
        email: this.getCampaignGiftingLeadEmail,
        name: this.getCampaignGiftingLeadName,
      };
    },
    getCampaignMetadata() {
      const status = "draft";

      const campaignName = this.getCampaignName?.trim() || "";
      const campaignBudgetID = this.getCampaignBudgetId || null;

      // console.log("campaignBudgetID: ", campaignBudgetID);
      // console.log(
      //   "getCampaignBudgetTracker.isBudgetLimitMet: ",
      //   this.getCampaignBudgetTracker?.isBudgetLimitMet
      // );

      if (
        this.getCampaignBudgetTracker?.isBudgetLimitMet ||
        !this.getCampaignBudgetId
      ) {
        // console.log(
        //   "getCampaignSelectedBudgetLimitIsMet: ",
        //   this.getCampaignSelectedBudgetLimitIsMet
        // );
        this.$store.commit("campaigns/SET_CAMPAIGN_BUILDER_BUDGET", null);
        this.$store.commit("campaigns/SET_CAMPAIGN_BUILDER_BUDGET_ID", null);
        // alert()
      }

      // Prepare metadata object
      const metadata = {
        "metadata.campaign_name": campaignName,
        "metadata.campaign_status": status,
        "metadata.campaign_type": this.getCampaignType || "",
        "metadata.gift_card_amount": this.getCampaignGiftCardAmount || 0,
        "metadata.budget": !this.getCampaignBudgetTracker?.isBudgetLimitMet
          ? campaignBudgetID
          : null,
        "metadata.collection_query.custom_selection_ids":
          this.customSelection || [],
        "metadata.collection_query.gift_card_amount":
          this.getCampaignGiftCardAmount || 0,
        "metadata.collection_query.requestBody":
          this.getCampaignSelectionRequestBody || {},

        ...(this.isTemplateBuilder && {
          "metadata.campaign_template.status": "draft",
          "metadata.campaign_template.isPrivate":
            this.getCampaignTemplateStatus,
        }),

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.giftNote":
            this.getCampaignLandingPageGiftNote,
        }),

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.enabled":
            this.getCampaignLandingPageEnabledStatus,
        }),

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.required":
            this.getCampaignLandingPageRequiredStatus,
        }),

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.signature":
            this.userMixinData.metadata.landingPageSettings.signature,
        }),

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.organizationLogo":
            this.userMixinData.metadata.landingPageSettings.organizationLogo,
        }),
      };

      return metadata;
    },

    getFinalCampaignMetadata(campaignBudgetID) {
      const status = "sent";
      const campaignName = this.getCampaignName.trim();
      const currentDate = new Date().toISOString();
      // console.log("currentDate: ", currentDate);

      return {
        "metadata.campaign_name": campaignName,

        "metadata.campaign_status": status,

        "metadata.campaign_type": this.getCampaignType,

        "metadata.gift_card_amount": this.getCampaignGiftCardAmount,

        "metadata.budget": campaignBudgetID,

        // "metadata.date_sent": currentDate,

        ...(!this.isTemplateBuilder && {
          "metadata.date_sent": currentDate,
        }),

        "metadata.collection_query.custom_selection_ids": this.customSelection,

        "metadata.collection_query.gift_card_amount":
          this.getCampaignGiftCardAmount,

        ...(this.isTemplateBuilder && {
          "metadata.campaign_template.status": "saved",
          "metadata.campaign_template.isPrivate":
            this.getCampaignTemplateStatus,
        }),

        "metadata.collection_query.requestBody":
          this.getCampaignSelectionRequestBody,

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.giftNote":
            this.getCampaignLandingPageGiftNote,
        }),

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.enabled":
            this.getCampaignLandingPageEnabledStatus,
        }),

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.required":
            this.getCampaignLandingPageRequiredStatus,
        }),

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.signature":
            this.userMixinData.metadata.landingPageSettings.signature,
        }),

        ...(this.getCampaignLandingPageData && {
          "metadata.landingPageSettings.organizationLogo":
            this.userMixinData.metadata.landingPageSettings.organizationLogo,
        }),
      };
    },
    addBookingDataToPatch(patch) {
      // Check if booking is enabled and a booking provider is selected
      const isBookingEnabled = this.getCampaignBookingEnabled;
      if (isBookingEnabled && this.getCampaignBookingProvider) {
        // If booking is enabled, add the booking data to the patch object
        patch.booking = this.getCampaignBookingData;
      } else if (!isBookingEnabled && this.getCampaignBookingProvider) {
        // If booking is not enabled but a booking provider is selected, add booking data and set a default summary
        patch.booking = this.getCampaignBookingData;
        patch.booking.summary = "Untitled booking";
      }
    },

    /** handle custom gift card selection */
    updateCampaignCustomSelection(selectedCards) {
      this.campaign.metadata.collection_query.custom_selection_ids =
        selectedCards;
      this.loadSteps();
    },

    /** handle retrieving default payment information */
    async getDefaultPaymentInformation() {
      this.dataIsLoading = true;

      try {
        const defaultPaymentData = await getDefaultPaymentInformation(
          this?.User?.id
        );

        const paymentInformation = defaultPaymentData;
        this.defaultPaymentInformation = paymentInformation;
      } catch (error) {
        if (
          error?.response?.data?.code === 404 &&
          error?.response?.data?.status === "no_payment_method_found"
        ) {
          console.log("error", error);
          console.log("no payment", error);

          console.error(error);
        } else {
          this.handleErrorFromAPI(
            error,
            "Something went wrong while attempting to retrieve your payment information from Stripe. Please reload the page and try again."
          );
          console.log("defaultPaymentRes error: ", error);
        }
      } finally {
        this.dataIsLoading = false;
      }
    },

    /** handle payment method setup */
    async handlePaymentMethodSetup() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      const campaignUpdated = await this.updateCampaign();
      if (campaignUpdated) {
        const successUrl =
          window.location.origin +
          `/campaign/edit/${this.campaign._id}/{CHECKOUT_SESSION_ID}`;
        const cancelUrl =
          window.location.origin +
          `/campaign/edit/${this.campaign._id}/cancelled`;

        const paymentMethodRes = await api.setupPaymentMethod({
          successUrl: successUrl,
          cancelUrl: cancelUrl,
        });
        // console.log("paymentMethodRes: ", paymentMethodRes);
        const paymentUrl = paymentMethodRes.data;
        window.location.href = paymentUrl.url;
      }
    },

    /** handle steps */
    async handleStepChange(step) {
      const successfulRes = await this.updateCampaign();
      if (successfulRes) {
        this.currentStep = step;
        this.loadSteps();
      }
    },
    async goToNextStep() {
      const successfulRes = await this.updateCampaign();
      if (successfulRes) {
        this.currentStep++;
        this.loadSteps();
      }
    },
    async saveAndExit() {
      const successfulRes = await this.updateCampaign();
      if (this.isTemplateBuilder && successfulRes) {
        this.$router.push("/templates");
      }
      if (!this.isTemplateBuilder && successfulRes) {
        this.$router.push("/campaigns");
      }
    },
    loadSteps() {
      let updatedSteps = this.updateSteps(this.campaign, this.steps);
      this.steps = updatedSteps;

      if (this.isTemplateBuilder) {
        updatedSteps = this.updateSteps(this.campaign, this.templateSteps);
        this.templateSteps = updatedSteps;
      }
    },
    updateSteps(campaign, steps) {
      const recipientsNotEmpty = this.getCampaignRecipients.length > 0;
      this.areCampaignRecipientsVisible = recipientsNotEmpty;
      const giftCardAmountComplete = this.getCampaignGiftCardAmount
        ? true
        : false;
      const campaignName = this.getCampaignName.trim();

      const giftCardSelectionComplete = this.customSelection;
      const campaignEmailComplete =
        this.getCampaignType === "email" &&
        this.getCampaignEmailSubject &&
        this.getCampaignEmailText;
      const usingShareableLink = this.getCampaignType === "link";
      // sm:tw-w-48
      const isBookingEnabled = this.getCampaignBookingEnabled;

      const bookingSummaryAndDescriptionComplete =
        this.getCampaignBookingSummary && this.getCampaignBookingDescription;

      let bookingComplete = true;

      if (isBookingEnabled && !bookingSummaryAndDescriptionComplete) {
        bookingComplete = false;
      }

      const isGiftNoteEnabled = this.getCampaignLandingPageEnabledStatus;

      const giftNoteTitleAndMessageComplete =
        this.getCampaignLandingPageTitle && this.getCampaignLandingPageMessage;
      let giftNoteComplete = true;

      if (isGiftNoteEnabled && !giftNoteTitleAndMessageComplete) {
        giftNoteComplete = false;
      }

      // console.log("bookingComplete", bookingComplete);
      let areCampaignTermsComplete = false;
      if (
        campaignName &&
        this.getCampaignGiftCardAmount &&
        (campaignEmailComplete || this.getCampaignType === "link") &&
        bookingComplete &&
        giftNoteComplete
      ) {
        areCampaignTermsComplete = true;
      }

      // Update Step 1
      this.updateStepStatus(steps, 1, recipientsNotEmpty ? 1 : 0);

      // Update Step 2
      this.updateStepStatus(steps, 2, areCampaignTermsComplete ? 1 : 0);

      // Update Step 3
      this.updateStepStatus(steps, 3, giftCardSelectionComplete ? 1 : 0);

      // Update Step 4
      this.updateStepStatus(
        steps,
        4,
        campaignEmailComplete || usingShareableLink ? 1 : 0
      );

      // Update Step 5
      this.updateStepStatus(
        steps,
        5,
        bookingComplete || usingShareableLink ? 1 : 0
      );

      // Update Step 5

      let allStepsComplete =
        campaignName &&
        recipientsNotEmpty &&
        giftCardAmountComplete &&
        giftCardSelectionComplete &&
        bookingComplete &&
        giftNoteComplete &&
        (campaignEmailComplete || usingShareableLink);
      this.updateStepStatus(steps, 4, allStepsComplete ? 1 : 0);

      if (this.isTemplateBuilder) {
        allStepsComplete =
          campaignName &&
          giftCardAmountComplete &&
          giftCardSelectionComplete &&
          bookingComplete &&
          giftNoteComplete &&
          (campaignEmailComplete || usingShareableLink);
      }

      this.campaignIsComplete = allStepsComplete;

      return steps;
    },
    updateStepStatus(steps, stepId, status) {
      const stepIndex = steps.findIndex((step) => step.id === stepId);
      if (stepIndex !== -1) {
        steps[stepIndex].status = status;
      }
    },

    /** handle resetting budget session id */
    async resetSessionId() {
      this.budgetSessionId = null;
      // this.loadSteps();
      const { campaignId } = this.$route.params;
      // Use replace to navigate to the same route without the CHECKOUT_SESSION_ID param
      this.$router.replace({ path: `/campaign/edit/${campaignId}` });
      // await this.loadCampaignDetails();
    },
    setNewBudget(newBudget) {
      // console.log("setNewBudget", newBudget);
      this.campaign.metadata.budget = newBudget;
      this.loadSteps();
    },

    /** handle sessionId logic */
    async handleSessionIdLogic() {
      const sessionId = this.$route.params.CHECKOUT_SESSION_ID;
      // console.log("sessionId: ", sessionId);

      const recipientsNotEmpty = this.getCampaignRecipients.length > 0;
      this.areCampaignRecipientsVisible = recipientsNotEmpty;

      const giftCardAmountComplete = Boolean(this.getCampaignGiftCardAmount);
      const campaignName = this.getCampaignName.trim();

      const giftCardSelectionComplete = this.customSelection;
      const campaignEmailComplete =
        this.getCampaignType === "email" &&
        this.getCampaignEmailSubject &&
        this.getCampaignEmailText;

      const usingShareableLink = this.getCampaignType === "link";
      const isBookingEnabled = this.getCampaignBookingEnabled;
      const isGiftNoteEnabled = this.getCampaignLandingPageEnabledStatus;
      const bookingSummaryAndDescriptionComplete =
        this.getCampaignBookingSummary && this.getCampaignBookingDescription;
      const giftNoteTitleAndMessageComplete =
        this.getCampaignLandingPageTitle && this.getCampaignLandingPageMessage;

      const bookingComplete =
        !isBookingEnabled || bookingSummaryAndDescriptionComplete;

      const giftNoteComplete =
        !isGiftNoteEnabled || giftNoteTitleAndMessageComplete;

      // console.log("bookingComplete", bookingComplete);

      const areCampaignTermsComplete =
        campaignName &&
        giftCardAmountComplete &&
        (campaignEmailComplete || usingShareableLink) &&
        bookingComplete &&
        giftNoteComplete;

      let allStepsComplete =
        campaignName &&
        recipientsNotEmpty &&
        giftCardAmountComplete &&
        giftCardSelectionComplete &&
        bookingComplete &&
        giftNoteComplete &&
        (campaignEmailComplete || usingShareableLink);

      if (this.isTemplateBuilder) {
        allStepsComplete =
          campaignName &&
          giftCardAmountComplete &&
          giftCardSelectionComplete &&
          bookingComplete &&
          giftNoteComplete &&
          (campaignEmailComplete || usingShareableLink);
      }

      if (sessionId) {
        if (sessionId !== "cancelled") {
          this.budgetSessionId = sessionId;
          // console.log("sessionId: ", sessionId);
          this.currentStep = 2;
        } else {
          await this.resetSessionId();
          this.currentStep = 2;
        }
      } else if (this.isTemplateBuilder) {
        if (!areCampaignTermsComplete) {
          this.currentStep = 2;
        } else if (!giftCardSelectionComplete) {
          this.currentStep = 3;
        }
        if (allStepsComplete) {
          this.currentStep = 4;
        }
      } else {
        if (!recipientsNotEmpty) {
          this.currentStep = 1;
        } else if (!areCampaignTermsComplete) {
          this.currentStep = 2;
        } else if (!giftCardSelectionComplete) {
          this.currentStep = 3;
        } else if (allStepsComplete) {
          this.currentStep = 4;
        }
      }
    },

    /** handle link copy */
    async copyLink() {
      const linkToCopy = this.campaignGiftUrl;
      await navigator.clipboard.writeText(linkToCopy);
      this.linkCopied = true;
    },

    /** success and error handling */
    handleSuccess(type) {
      if (type === "link") {
        this.campaignIsSaving = false;
        console.warn("link campaign sent");
        this.sentMessage.link.successful.show = true;
      } else if (type === "email") {
        this.campaignIsSaving = false;
        this.sentMessage.email.successful.show = true;
        console.warn("email campaign sent");
      } else {
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Your campaign was saved.",
          duration: 2000,
          id: Date.now(),
        });
        this.nameEditIsActive = false;
        this.loadCampaignDetails();
        this.campaignIsSaving = false;
        if (this.getCampaignRecipientsIds.length > 0) {
          this.$store.commit(
            "campaigns/SET_CAMPAIGN_BUILDER_RECIPIENTS_VISIBLE",
            true
          );
        }
      }
    },
    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error?.response) {
        // Process API error message if customMessage is not provided
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });
      this.loadingDashboardData = false;
      this.campaignIsSaving = false;
      this.campaignsAreLoading = false;
      console.error(errorMessage); // Keep for debugging purposes
    },

    /** created logic */
    async handleCreatedLifecycleLogic() {
      await this.getDefaultPaymentInformation();
      await this.loadCampaignDetails();
      await this.handleSessionIdLogic();
      this.loadSteps();
    },
  },
};
</script>

<style scoped></style>
