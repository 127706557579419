<template>
  
  <PageHeader :showActions="true" title="Regions">
    <template #page-header-actions>
      <TheButton
        type="button"
        :hasLeadingIcon="true"
        :hasTrailingIcon="true"
        variant="primary"
        size="large"
        @click="toggleNewRegionModal()"
      >
        <template #text>Create region </template>
        <template #leading-icon><b-icon-plus-lg></b-icon-plus-lg></template>
      </TheButton>
    </template>
  </PageHeader>
  <NotificationMessage :scope="$options.name"></NotificationMessage>

  <ThePage customWidthClass="tw-min-w-[600px] tw-max-w-7xl">
    <RegionsTable :isNewRegionModalVisible="isNewRegionModalVisible" @closeModal="closeModal"
     
    ></RegionsTable>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import RegionsTable from "../../components-v2/regions/RegionsTable";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import RegionForm from "../../components-v2/ui/modals/create/RegionForm.vue";

export default {
  name: "ViewRecipientsGroups",
  emits: [""],
  components: {
    TheModal,
    RegionForm,
    TheButton,
    RegionsTable,
    ThePage,
    PageHeader,
    NotificationMessage,
  },
  data() {
    return {
      isNewRegionModalVisible: false,
    };
  },
  methods: {
    /** other */
    async checkIfUserHasGroups(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.currentPage = page === undefined ? this.currentPage : page;
      this.checkingIfUserHasGroups = true;
      let requestBody = {};
      let filter = {
        model: "Recipient",
        "metadata.admins": { $eq: this.userId },
      };

      // let selection = "metadata";

      const queryParameters = {
        limit: 1,
      };

      requestBody = {
        filter: filter,
        // selection: selection,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);

        const count = response.data.result.count;
        this.count = response.data.result.count;
        // this.userHasGroups = count;
        // this.userHasGroups
        this.groups = response.data.result.records;
        return count ? true : false;
      } catch (error) {
        console.log("recipients did not load", error);
      } finally {
        this.checkingIfUserHasGroups = false;
      }
    },
    reloadList() {
      this.$emit("reloadList");
    },

    toggleNewRegionModal() {
      // alert();
      this.isNewRegionModalVisible = !this.isNewRegionModalVisible;
      // this.isNewRegionModalVisible = !this.isNewRegionModalVisible;
    },
    toggleNewSegmentModal() {
      // alert();
      // this.isNewRegionModalVisible = !this.isNewRegionModalVisible;
      this.isNewRegionModalVisible = !this.isNewRegionModalVisible;
    },
    closeModal() {
      this.isNewRegionModalVisible = false;
    },
  },
};
</script>

<style scoped></style>
